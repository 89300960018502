import { Box, Divider, List, ListItem, ListItemText, Typography } from "@mui/material";
import { useState } from "react";
import TextField from "../../MaterialUI/FormFields/TextFields";
import { useLanguageContext } from "../../../context/LanguageContext";

interface CommentData {
  comment: string;
  date: string;
}

interface CommentModalLayoutProps {}

const CommentModalLayout: React.FC<CommentModalLayoutProps> = () => {
  const { t } = useLanguageContext();

  const TEMP_DATA: CommentData[] = [
    { comment: "This is a test comment", date: "Jan 9, 2014" },
    { comment: "This is a test comment 2", date: "Jan 10, 2014" },
  ];

  const [commentsData] = useState<CommentData[]>(TEMP_DATA);

  return (
    <Box component="div">
      <TextField label={t("Type your comment here")} />

      <Box component="div">
        <Typography mt={4} mb={2} variant="h4" component="p">
          {t("All Comments")}
        </Typography>
        <List>
          {commentsData.map((comments, index) => (
            <Box component="div" key={`${index}-${comments.date}`}>
              <Divider variant="middle" />
              <ListItem>
                <ListItemText primary={comments.comment} secondary={comments.date} />
              </ListItem>
              <Divider variant="middle" />
            </Box>
          ))}
        </List>
      </Box>
    </Box>
  );
};

export default CommentModalLayout;

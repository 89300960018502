import { Query } from "../../../Api/callApi";

export const getQueryElpromCurrencies = (code: string): Query => ({
  endpoint: `lprom/currencies?currency_code=${code}`,
  method: "GET",
});

export const getQueryElpromCurrencySync = (): Query => ({
  endpoint: `lprom/synchronize-currency-rates`,
  method: "GET",
});

export const getQueryElpromFilesMetadata = (): Query => ({
  endpoint: "lprom/files/metadata",
  method: "GET",
});

export const getQueryElpromDates = (): Query => ({
  endpoint: `/lprom/collateral/dates`,
  method: "GET",
});

type Params = {
  limit: number;
  offset: number;
  language_code: "en" | "bg";
};
export const getQueryElpromKso = ({ limit, language_code, offset }: Params): Query => ({
  endpoint: `lprom/kso?limit=${limit}&offset=${offset}&language_code=${language_code}`,
  method: "GET",
});
export const getQueryElpromSupco = ({ limit, language_code, offset }: Params): Query => ({
  endpoint: `lprom/supco?limit=${limit}&offset=${offset}&language_code=${language_code}`,
  method: "GET",
});
export const getQueryElpromPrices = ({
  limit,
  language_code,
  offset,
}: Params): Query => ({
  endpoint: `lprom/prices?limit=${limit}&offset=${offset}&language_code=${language_code}`,
  method: "GET",
});

export const getQueryElpromPricesPo = ({
  limit,
  language_code,
  offset,
}: Params): Query => ({
  endpoint: `lprom/prices_po?limit=${limit}&offset=${offset}&language_code=${language_code}`,
  method: "GET",
});

export const getQueryElpromDetails = (lng: "en" | "bg", date: string): Query => ({
  endpoint: `lprom/collateral/details?language_code=${lng}&target_date=${date}`,
  method: "GET",
});
export const getQueryElpromSummary = (lng: "en" | "bg"): Query => ({
  endpoint: `lprom/collateral/summary?language_code=${lng}`,
  method: "GET",
});

export const getQueryElpromOrderMaterials = (order: string): Query => ({
  endpoint: `lprom/client-order/${order}/materials`,
  method: "GET",
});

export const getQueryElpromCollateralDetailsFile = (): Query => ({
  endpoint: `lprom/excel-export/collateral`,
  method: "GET",
  returnJson: false,
});

export const getQueryElpromCollateralNotDistributed = (
  language_code: Pick<Params, "language_code">,
  date: string
): Query => ({
  endpoint: `lprom/priority/0/materials?language_code=${language_code}&target_date=${date}`,
  method: "GET",
});

export const getQueryElpromKsoClientOrder = (
  client_order: string,
  limit: number,
  offset: number,
  language_code: Pick<Params, "language_code">
): Query => ({
  endpoint: `lprom/kso/${client_order}`,
  method: "GET",
  variables: {
    limit,
    language_code,
    offset,
  },
});

export const getQueryElpromCompareData = (
  language_code: Pick<Params, "language_code">,
  date: string,
  compareDate: string
): Query => ({
  endpoint: `lprom/collateral/compare?language_code=${language_code}&target_date_1=${date}&target_date_2=${compareDate}`,
  method: "GET",
});

export const getQueryElpromCompareMaterialsData = (
  language_code: Pick<Params, "language_code">,
  order: string,
  date: string,
  compareDate: string
): Query => ({
  endpoint: `lprom/client-order/${order}/materials/discrepencies?language_code=${language_code}&target_date_1=${date}&target_date_2=${compareDate}`,
  method: "GET",
});

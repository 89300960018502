import { SerializedStyles } from "@emotion/react";
import { ExcellenceChartOption } from "./excellenceUtils";
import { Box, Grid, Typography } from "@mui/material";
import AddExcellenceChartIconButton from "./AddExcellenceChartIconButton";
import boxPlotDefaultData from "../../ExcellenceWidgets/AdvancedWidgets/AdvancedDefaultConfig/boxPlotDefaultData";
import calendarWidgetDefaultData from "../../ExcellenceWidgets/AdvancedWidgets/AdvancedDefaultConfig/calendarWidgetDefaultData";
import funnelDefaultData from "../../ExcellenceWidgets/AdvancedWidgets/AdvancedDefaultConfig/funnelDefaultData";
import heatMapDefaultData from "../../ExcellenceWidgets/AdvancedWidgets/AdvancedDefaultConfig/heatMapDefaultData";
import networkDefaultData from "../../ExcellenceWidgets/AdvancedWidgets/AdvancedDefaultConfig/networkDefaultData";
import sankeyDefaultData from "../../ExcellenceWidgets/AdvancedWidgets/AdvancedDefaultConfig/sankeyDefaultData";
import scatterPlotDefaultData from "../../ExcellenceWidgets/AdvancedWidgets/AdvancedDefaultConfig/scatterPlotDefaultData";
import sunburstDefaultData from "../../ExcellenceWidgets/AdvancedWidgets/AdvancedDefaultConfig/sunburstDefaultData";
import swarmPlotDefaultData from "../../ExcellenceWidgets/AdvancedWidgets/AdvancedDefaultConfig/swarmPlotDefaultData";
import timeRangeDefaultData from "../../ExcellenceWidgets/AdvancedWidgets/AdvancedDefaultConfig/timeRangeDefaultData";
import treeMapDefaultData from "../../ExcellenceWidgets/AdvancedWidgets/AdvancedDefaultConfig/treeMapDefaultData";
import { areaChartDefaultConfiguration } from "../../ExcellenceWidgets/ExcellenceDefaultConfig/areaChartDefaultConfiguration";
import areaChartDefaultData from "../../ExcellenceWidgets/ExcellenceDefaultConfig/areaChartDefaultData";
import barChartDefaultConfiguration from "../../ExcellenceWidgets/ExcellenceDefaultConfig/barChartDefaultConfiguration";
import barChartDefaultData from "../../ExcellenceWidgets/ExcellenceDefaultConfig/barChartDefaultData";
import columnChartDefaultData from "../../ExcellenceWidgets/ExcellenceDefaultConfig/columnChartDefaultData";
import excellenceTableDefaultConfiguration from "../../ExcellenceWidgets/ExcellenceDefaultConfig/excellenceTableDefaultConfiguration";
import {
  EXCELLENCE_TABLE_DEFAULT_ROWS,
  EXCELLENCE_TABLE_DEFAULT_COLUMNS,
} from "../../ExcellenceWidgets/ExcellenceDefaultConfig/excellenceTableDefaultData";
import gaugeWidgetDefaultData from "../../ExcellenceWidgets/ExcellenceDefaultConfig/gaugeWidgetDefaultData";
import indicatorWidgetDefaultConfiguration from "../../ExcellenceWidgets/ExcellenceDefaultConfig/indicatorWidgetDefaultConfiguration";
import indicatorWidgetDefaultData from "../../ExcellenceWidgets/ExcellenceDefaultConfig/indicatorWidgetDefaultData";
import { lineChartDefaultConfiguration } from "../../ExcellenceWidgets/ExcellenceDefaultConfig/lineChartDefaultConfiguration";
import { pieChartDefaultConfiguration } from "../../ExcellenceWidgets/ExcellenceDefaultConfig/pieChartDefaultConfiguration";
import pieChartDefaultData from "../../ExcellenceWidgets/ExcellenceDefaultConfig/pieChartDefaultData";
import radarChartDefaultConfiguration from "../../ExcellenceWidgets/ExcellenceDefaultConfig/radarChartDefaultConfiguration";
import radarChartDefaultData from "../../ExcellenceWidgets/ExcellenceDefaultConfig/radarChartDefaultData";
import textWidgetDefaultConfiguration from "../../ExcellenceWidgets/ExcellenceDefaultConfig/textWidgetDefaultConfiguration";
import textWidgetDefaultData from "../../ExcellenceWidgets/ExcellenceDefaultConfig/textWidgetDefaultData";
import { gaugeChartDefaultConfiguration } from "../../ExcellenceWidgets/ExcellenceDefaultConfig/gaugeChartDefaultConfiguration";
import boxPlotDefaultConfig from "../../ExcellenceWidgets/AdvancedWidgets/AdvancedDefaultConfig/boxPlotDefaultConfig";
import heatMapDefaultConfig from "../../ExcellenceWidgets/AdvancedWidgets/AdvancedDefaultConfig/heatMapDefaultConfig";
import lineChartDefaultData from "../../ExcellenceWidgets/ExcellenceDefaultConfig/lineChartDefaultData";

interface AddNewExcellenceChartProps {
  css?: SerializedStyles[] | SerializedStyles;
  className?: string;
  handleAddNewChart: (chart: ExcellenceChartOption, title: string) => void;
  isExcellence: boolean;
}

const AddNewExcellenceChart: React.FC<AddNewExcellenceChartProps> = ({
  className,
  handleAddNewChart,
  isExcellence,
}) => {
  return (
    <Box component="div" className={className} sx={{ maxWidth: "600px" }}>
      <Typography variant="h4" color="textSecondary" mb={1} ml={1}>
        Excellence
      </Typography>
      <Grid container spacing={0.6}>
        <Grid item xs={2}>
          <AddExcellenceChartIconButton
            type="lineChart"
            onClick={() =>
              handleAddNewChart(
                {
                  lineChart: {
                    config: lineChartDefaultConfiguration,
                    data: lineChartDefaultData,
                    dataSchema: null,
                  },
                },
                "Line Chart"
              )
            }
          />
        </Grid>
        <Grid item xs={2}>
          <AddExcellenceChartIconButton
            type="timeChart"
            onClick={() =>
              handleAddNewChart(
                {
                  timeChart: {
                    config: lineChartDefaultConfiguration,
                    data: null,
                    dataSchema: null,
                    dateTimeConfig: null,
                  },
                },
                "Time Chart"
              )
            }
          />
        </Grid>
        <Grid item xs={2}>
          <AddExcellenceChartIconButton
            type="barChart"
            onClick={() =>
              handleAddNewChart(
                {
                  barChart: {
                    config: barChartDefaultConfiguration(false),
                    data: barChartDefaultData,
                    dataSchema: null,
                  },
                },
                "Bar Chart"
              )
            }
          />
        </Grid>
        <Grid item xs={2}>
          <AddExcellenceChartIconButton
            type="radarChart"
            onClick={() =>
              handleAddNewChart(
                {
                  radarChart: {
                    config: radarChartDefaultConfiguration,
                    data: radarChartDefaultData,
                    dataSchema: null,
                  },
                },
                "Radar Chart"
              )
            }
          />
        </Grid>
        <Grid item xs={2}>
          <AddExcellenceChartIconButton
            type="columnChart"
            onClick={() =>
              handleAddNewChart(
                {
                  columnChart: {
                    config: barChartDefaultConfiguration(true),
                    data: columnChartDefaultData,
                    dataSchema: null,
                  },
                },
                "Column Chart"
              )
            }
          />
        </Grid>

        <Grid item xs={2}>
          <AddExcellenceChartIconButton
            type="areaChart"
            onClick={() =>
              handleAddNewChart(
                {
                  areaChart: {
                    config: areaChartDefaultConfiguration,
                    data: areaChartDefaultData,
                    dataSchema: null,
                  },
                },
                "Area Chart"
              )
            }
          />
        </Grid>
        <Grid item xs={2}>
          <AddExcellenceChartIconButton
            type="pieChart"
            onClick={() =>
              handleAddNewChart(
                {
                  pieChart: {
                    config: pieChartDefaultConfiguration,
                    data: pieChartDefaultData,
                    dataSchema: null,
                  },
                },
                "Pie Chart"
              )
            }
          />
        </Grid>
        <Grid item xs={2}>
          <AddExcellenceChartIconButton
            type="donutChart"
            onClick={() =>
              handleAddNewChart(
                {
                  donutChart: {
                    config: pieChartDefaultConfiguration,
                    data: pieChartDefaultData,
                    dataSchema: null,
                  },
                },
                "Donut Chart"
              )
            }
          />
        </Grid>
        <Grid item xs={2}>
          <AddExcellenceChartIconButton
            type="table"
            onClick={() =>
              handleAddNewChart(
                {
                  table: {
                    config: excellenceTableDefaultConfiguration,
                    data: {
                      rows: EXCELLENCE_TABLE_DEFAULT_ROWS,
                      columns: EXCELLENCE_TABLE_DEFAULT_COLUMNS,
                    },
                    dataSchema: null,
                  },
                },
                "Table"
              )
            }
          />
        </Grid>
        <Grid item xs={2}>
          <AddExcellenceChartIconButton
            type="gaugeWidget"
            onClick={() =>
              handleAddNewChart(
                {
                  gaugeWidget: {
                    config: gaugeChartDefaultConfiguration,
                    data: gaugeWidgetDefaultData,
                    dataSchema: null,
                  },
                },
                "Gauge"
              )
            }
          />
        </Grid>

        <Grid item xs={2}>
          <AddExcellenceChartIconButton
            type="textWidget"
            onClick={() =>
              handleAddNewChart(
                {
                  textWidget: {
                    config: textWidgetDefaultConfiguration,
                    data: null,
                    dataSchema: {
                      content: textWidgetDefaultData,
                    },
                  },
                },
                "Text"
              )
            }
          />
        </Grid>
        <Grid item xs={2}>
          <AddExcellenceChartIconButton
            type="indicatorWidget"
            onClick={() =>
              handleAddNewChart(
                {
                  indicatorWidget: {
                    config: indicatorWidgetDefaultConfiguration,
                    data: indicatorWidgetDefaultData,
                    dataSchema: null,
                  },
                },
                "Indicator"
              )
            }
          />
        </Grid>
      </Grid>

      {!isExcellence ? (
        <>
          <Typography variant="h4" color="textSecondary" mt={3} mb={1} ml={1}>
            Advanced
          </Typography>
          <Grid container spacing={0.6}>
            <Grid item xs={2}>
              <AddExcellenceChartIconButton
                type="boxPlot"
                onClick={() =>
                  handleAddNewChart(
                    {
                      boxPlot: {
                        config: boxPlotDefaultConfig,
                        data: boxPlotDefaultData,
                        dataSchema: null,
                      },
                    },
                    "Box Plot"
                  )
                }
              />
            </Grid>
            <Grid item xs={2}>
              <AddExcellenceChartIconButton
                type="heatMap"
                onClick={() =>
                  handleAddNewChart(
                    {
                      heatMap: {
                        config: heatMapDefaultConfig,
                        data: heatMapDefaultData,
                        dataSchema: null,
                      },
                    },
                    "Heat Map"
                  )
                }
              />
            </Grid>
            <Grid item xs={2}>
              <AddExcellenceChartIconButton
                type="calendar"
                onClick={() =>
                  handleAddNewChart(
                    {
                      calendar: {
                        config: null,
                        data: calendarWidgetDefaultData,
                        dataSchema: null,
                      },
                    },
                    "Calendar"
                  )
                }
              />
            </Grid>
            <Grid item xs={2}>
              <AddExcellenceChartIconButton
                type="funnel"
                onClick={() =>
                  handleAddNewChart(
                    {
                      funnel: {
                        config: null,
                        data: funnelDefaultData,
                        dataSchema: null,
                      },
                    },
                    "Funnel"
                  )
                }
              />
            </Grid>
            <Grid item xs={2}>
              <AddExcellenceChartIconButton
                type="network"
                onClick={() =>
                  handleAddNewChart(
                    {
                      network: {
                        config: null,
                        data: networkDefaultData,
                        dataSchema: null,
                      },
                    },
                    "Network"
                  )
                }
              />
            </Grid>

            <Grid item xs={2}>
              <AddExcellenceChartIconButton
                type="sankey"
                onClick={() =>
                  handleAddNewChart(
                    {
                      sankey: {
                        config: null,
                        data: sankeyDefaultData,
                        dataSchema: null,
                      },
                    },
                    "Sankey"
                  )
                }
              />
            </Grid>
            <Grid item xs={2}>
              <AddExcellenceChartIconButton
                type="scatterPlot"
                onClick={() =>
                  handleAddNewChart(
                    {
                      scatterPlot: {
                        config: null,
                        data: scatterPlotDefaultData,
                        dataSchema: null,
                      },
                    },
                    "Scatter Plot"
                  )
                }
              />
            </Grid>
            <Grid item xs={2}>
              <AddExcellenceChartIconButton
                type="sunburst"
                onClick={() =>
                  handleAddNewChart(
                    {
                      sunburst: {
                        config: null,
                        data: sunburstDefaultData,
                        dataSchema: null,
                      },
                    },
                    "Sunburst"
                  )
                }
              />
            </Grid>
            <Grid item xs={2}>
              <AddExcellenceChartIconButton
                type="swarmPlot"
                onClick={() =>
                  handleAddNewChart(
                    {
                      swarmPlot: {
                        config: null,
                        data: swarmPlotDefaultData,
                        dataSchema: null,
                      },
                    },
                    "Swarm Plot"
                  )
                }
              />
            </Grid>
            <Grid item xs={2}>
              <AddExcellenceChartIconButton
                type="timeRange"
                onClick={() =>
                  handleAddNewChart(
                    {
                      timeRange: {
                        config: null,
                        data: timeRangeDefaultData,
                        dataSchema: null,
                      },
                    },
                    "Time Range"
                  )
                }
              />
            </Grid>
            <Grid item xs={2}>
              <AddExcellenceChartIconButton
                type="treeMap"
                onClick={() =>
                  handleAddNewChart(
                    {
                      treeMap: {
                        config: null,
                        data: treeMapDefaultData,
                        dataSchema: null,
                      },
                    },
                    "Tree Map"
                  )
                }
              />
            </Grid>
          </Grid>
        </>
      ) : null}
    </Box>
  );
};

export default AddNewExcellenceChart;

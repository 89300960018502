import { Box, Stack, Typography } from "@mui/material";
import { LegendDatum } from "@nivo/pie";
import { PieDonutChartData } from "../EditExcellenceChartForms/excellenceChartFormUtils";
import {
  getPieChartLegendText,
  getPieCustomLegendParamName,
  PieChartDataSchema,
} from "./pieChartTypes";

interface PieChartLegendProps {
  customLegends: LegendDatum<PieDonutChartData>[];
  schema: PieChartDataSchema;
  paramMapping: Record<string, string>;
  handleToggleVisibility: (id: string) => void;
}

const PieChartLegend: React.FC<PieChartLegendProps> = ({
  customLegends,
  schema,
  paramMapping,
  handleToggleVisibility,
}) => {
  return (
    <>
      <Box
        component="div"
        style={{ height: "250px", overflowY: "auto", marginRight: "1rem" }}
      >
        <Typography mb={2} variant="body1" textAlign="center">
          {getPieChartLegendText(schema, paramMapping)}
        </Typography>

        <Stack spacing={3} flexWrap="wrap" direction="row">
          {customLegends.map((item, index) => (
            <Box
              component="div"
              key={item.id}
              onClick={() => handleToggleVisibility(String(item.id))}
              style={{ cursor: "pointer" }}
            >
              <Stack
                ml={index === 0 ? 3 : 0}
                mb={1.5}
                spacing={1}
                alignItems="center"
                direction="row"
              >
                <Box
                  component="div"
                  style={{
                    width: "15px",
                    height: "15px",
                    borderRadius: "100%",
                    backgroundColor: item.color,
                    opacity: item.hidden ? 0.3 : 1,
                  }}
                />

                <Typography variant="body2">
                  {getPieCustomLegendParamName(item.label, paramMapping)}
                </Typography>
              </Stack>
            </Box>
          ))}
        </Stack>
      </Box>
    </>
  );
};

export default PieChartLegend;

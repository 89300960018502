import { useEffect, useState } from "react";
import ResponsiveTableGrid from "../../Components/SmallComponents/TableGrid/ResponsiveTableGrid";
import callApi from "../../Api/callApi";
import { useAuthedContext } from "../../context/AuthContext";
import PageTitle from "../../Components/SmallComponents/PageTitle/PageTitle";
import { useOutletContext } from "react-router-dom";
import { AppRouterProps } from "../../Layout/layoutVariables";
import { Box, Tab, Tabs, useTheme } from "@mui/material";
import { useLanguageContext } from "../../context/LanguageContext";
import { useTranslateFields } from "../../Global/Hooks/useTranslations";
import {
  GetQueryStocks,
  GetQueryStocksOverview,
} from "../../Api/SupplyChain/apiSupplyChainSnippets";
import {
  getQueryStocks,
  getQueryStocksOverview,
} from "../../Api/SupplyChain/apiSupplyChainGetQueries";
import {
  StockRow,
  StocksOverviewRow,
} from "../../Components/PageComponents/MyWarehouses/stocksUtils";
import { TableGridColumnSchema } from "../../Components/SmallComponents/TableGrid/constructTableGrid";
import cssSpacingStyles from "../../Global/Styles/spacing";

const StocksPage = () => {
  const { t } = useLanguageContext();
  const theme = useTheme();

  const styles = { ...cssSpacingStyles(theme) };
  const [tabView, setTabView] = useState<number>(0);
  const [stocksRows, setStocksRows] = useState<StockRow[]>();
  const [stocksColumns, setStocksColumns] = useState<TableGridColumnSchema[]>();
  const [stockOverviewRows, setStockOverviewRows] = useState<StocksOverviewRow[]>();
  const [stockoverviewColumns, setStockOverviewColumns] =
    useState<TableGridColumnSchema[]>();
  const [pageLoading, setPageLoading] = useState<boolean>(true);
  const { setAuthedUser } = useAuthedContext();
  const { smMediaQuery, setExtraTopNavMenu } = useOutletContext<AppRouterProps>();

  useEffect(() => {
    if (smMediaQuery) {
      setExtraTopNavMenu(null);
    } else {
      setExtraTopNavMenu(<PageTitle title={t("Stock Control")} />);
    }

    return () => {
      setExtraTopNavMenu(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [smMediaQuery]);

  useEffect(() => {
    (async () => {
      setPageLoading(true);
      await fetchStocksData();
      setPageLoading(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchStocksData = async () => {
    try {
      const stocks = await callApi<GetQueryStocks>({
        query: getQueryStocks(),
        auth: { setAuthedUser },
      });

      const stocksOverview = await callApi<GetQueryStocksOverview>({
        query: getQueryStocksOverview(),
        auth: { setAuthedUser },
      });

      setStockOverviewRows(stocksOverview.rows);
      setStockOverviewColumns(stocksOverview.columns);

      setStocksRows(stocks.rows);
      setStocksColumns(stocks.columns);
    } catch (error) {
      console.log("There was an error fetching stocks ", error);
    }
  };

  return (
    <>
      {smMediaQuery ? (
        <PageTitle css={styles.labelBreak} title={t("Stock Control")} />
      ) : null}

      <Box component="div">
        <Tabs
          css={styles.sectionBreak}
          value={tabView}
          onChange={(_, val) => setTabView(val)}
          aria-label="configure chart tabs"
          centered
        >
          <Tab label={t("Stocks")} disabled={pageLoading} />
          <Tab label={t("Overview")} disabled={pageLoading} />
        </Tabs>

        {tabView === 0 ? (
          <Box component="div">
            <ResponsiveTableGrid
              rows={stocksRows || []}
              colSchema={useTranslateFields(stocksColumns || [], ["label"])}
              editMode={false}
              responsive="responsive"
              configuration={{
                density: "compact",
              }}
              loading={pageLoading}
              tableID="Stocks_table"
            />
          </Box>
        ) : null}

        {tabView === 1 ? (
          <ResponsiveTableGrid
            rows={stockOverviewRows || []}
            colSchema={useTranslateFields(stockoverviewColumns || [], ["label"])}
            editMode={false}
            responsive="responsive"
            configuration={{
              density: "compact",
            }}
            loading={pageLoading}
            tableID="StocksOverview_table"
          />
        ) : null}
      </Box>
    </>
  );
};

export default StocksPage;

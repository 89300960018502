import { SerializedStyles } from "@emotion/react";
import { Box, Grid, Stack, Typography } from "@mui/material";
import useTheme from "@mui/material/styles/useTheme";
import cssLayoutStyles from "../../../Global/Styles/layout";
import cssSpacingStyles from "../../../Global/Styles/spacing";
import { useEffect, useState } from "react";
import {
  DateTimeCommonPeriod,
  ExcellenceDateTimeConfigurationData,
  PeriodTimeSymbol,
  dateTimeCommonPeriodOptions,
  dateTimeFetchRateOptions,
  getPeriodValueNumber,
  periodSymbolMapping,
  periodSymbolOptions,
} from "./dateTimeConfigurationUtils";
import DateAndTimePicker from "../../MaterialUI/DateTimePickers/DateAndTimePicker";
import {
  dateOrStringTransform,
  formatDateAndTime,
  handleGetSelectOption,
} from "../../../Global/Utils/commonFunctions";
import RadioGroup from "../../MaterialUI/FormFields/RadioGroup";
import debounce from "lodash.debounce";
import Select from "../../MaterialUI/FormFields/Select";
import cssFontStyles from "../../../Global/Styles/font";
import Collapse from "../../MaterialUI/Collapse";
import {
  startOfDay,
  endOfDay,
  subDays,
  startOfWeek,
  endOfWeek,
  subWeeks,
  startOfMonth,
  endOfMonth,
  subMonths,
  startOfQuarter,
  subQuarters,
  endOfQuarter,
  endOfYear,
  startOfYear,
  subYears,
} from "date-fns";

type Mode = "Live data" | "Static data" | "Common periods";
const MODE_OPTIONS: Mode[] = ["Live data", "Static data", "Common periods"];

const INITIAL_DATA: ExcellenceDateTimeConfigurationData = {
  dateFrom: null,
  dateTo: null,
  liveDataPeriodNumber: null,
  liveDataPeriodTime: null,
  //
  commonPeriod: null,
  commonPeriodStart: null,
  commonPeriodEnd: null,
  commonPeriodFetchRate: null,
};

interface DateTimeConfigurationProps {
  css?: SerializedStyles[] | SerializedStyles;
  className?: string;
  timeConfigData: ExcellenceDateTimeConfigurationData | null;
  handleUpdateData: (data: ExcellenceDateTimeConfigurationData) => void;
  setRemoveInterval: React.Dispatch<React.SetStateAction<boolean>>;
  disabled: boolean;
  isOEEConfiguration?: boolean;
}

const DateTimeConfiguration: React.FC<DateTimeConfigurationProps> = ({
  className,
  timeConfigData,
  handleUpdateData,
  setRemoveInterval,
  disabled,
  isOEEConfiguration,
}) => {
  const theme = useTheme();
  const styles = {
    ...cssSpacingStyles(theme),
    ...cssFontStyles,
    ...cssLayoutStyles,
  };
  const availableModes: Mode[] = isOEEConfiguration
    ? ["Static data", "Common periods"]
    : MODE_OPTIONS;

  const [mode, setMode] = useState<Mode>(availableModes[0]);

  const [stateTimeConfigData, setStateTimeConfigData] =
    useState<ExcellenceDateTimeConfigurationData>(timeConfigData || INITIAL_DATA);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceUpdateData = debounce(handleUpdateData, 500);

  const { liveDataTextOne } = getTimeConfigDataText(stateTimeConfigData);
  const periodText = getCommonPeriodText(stateTimeConfigData);

  useEffect(() => {
    if (
      timeConfigData &&
      JSON.stringify(timeConfigData) !== JSON.stringify(stateTimeConfigData)
    ) {
      setStateTimeConfigData(() => timeConfigData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeConfigData]);

  useEffect(() => {
    if (JSON.stringify(stateTimeConfigData) !== JSON.stringify(timeConfigData)) {
      debounceUpdateData(stateTimeConfigData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateTimeConfigData]);

  useEffect(() => {
    if (isOEEConfiguration) {
      setStateTimeConfigData((prev) => ({
        ...prev,
        dateFrom: startOfMonth(new Date()),
        dateTo: new Date(),
      }));
    }
  }, [isOEEConfiguration]);

  const handleChangeView = (value: Mode) => {
    if (value === "Live data") {
      setRemoveInterval(false);
    } else {
      setRemoveInterval(true);
    }
    setMode(value);
  };

  const handleChangeStaticData = (key: string, value: Date | null) => {
    setStateTimeConfigData((prev) => {
      switch (key) {
        case "dateFrom": {
          return {
            ...INITIAL_DATA,
            dateFrom: value,
            dateTo: prev.dateTo,
          };
        }
        case "dateTo": {
          return {
            ...INITIAL_DATA,
            dateTo: value,
            dateFrom: prev.dateFrom,
          };
        }
        default: {
          return INITIAL_DATA;
        }
      }
    });
  };

  const handleChangeLiveData = (key: string, value: any) => {
    setStateTimeConfigData((prev) => {
      const resetStatic = {
        dateFrom: null,
        dateTo: null,
        commonPeriod: null,
        commonPeriodStart: null,
        commonPeriodEnd: null,
        commonPeriodFetchRate: null,
      };

      const resetLiveEnd = {} as ExcellenceDateTimeConfigurationData;

      return {
        ...prev,
        ...resetStatic,
        [key]: value,
        ...resetLiveEnd,
      };
    });
  };

  const handleChangeCommonPeriodData = (key: string, value: string | null) => {
    setStateTimeConfigData((prev) => {
      switch (key) {
        case "commonPeriod": {
          const fetchRate =
            value && value.startsWith("Current") ? prev.commonPeriodFetchRate : null;
          const dates = getCommonPeriodStartEndDates(value as DateTimeCommonPeriod);

          return {
            ...INITIAL_DATA,
            commonPeriod: value,
            commonPeriodFetchRate: fetchRate,
            commonPeriodStart: dates.startDate,
            commonPeriodEnd: dates.endDate,
          };
        }
        case "commonPeriodFetchRate": {
          return {
            ...INITIAL_DATA,
            commonPeriod: prev.commonPeriod,
            commonPeriodFetchRate: value,
            commonPeriodStart: prev.commonPeriodStart,
            commonPeriodEnd: prev.commonPeriodEnd,
          };
        }
        default: {
          return INITIAL_DATA;
        }
      }
    });
  };

  return (
    <Box component="div" css={styles.width100} className={className}>
      <RadioGroup
        css={styles.contentBreak}
        radioOptions={handleGetSelectOption(availableModes)}
        label="Date and time mode"
        value={mode}
        onChange={(e) => handleChangeView(e.target.value as Mode)}
        row
        disabled={disabled}
      />

      {mode === "Live data" ? (
        <Box component="div" css={styles.width100}>
          <Grid spacing={3} container>
            <Grid item xs={7}>
              <Select
                selectOptions={periodSymbolOptions}
                label="From"
                fullWidth
                value={stateTimeConfigData.liveDataPeriodTime || ""}
                onChange={(e) =>
                  handleChangeLiveData("liveDataPeriodTime", e.target.value)
                }
                disabled={disabled}
              />
            </Grid>
            <Grid item xs={5}>
              <Select
                fullWidth
                label="From Value"
                value={stateTimeConfigData.liveDataPeriodNumber || ""}
                onChange={(e) =>
                  handleChangeLiveData("liveDataPeriodNumber", e.target.value)
                }
                selectOptions={getPeriodValueNumber(
                  stateTimeConfigData.liveDataPeriodTime as PeriodTimeSymbol
                )}
                disabled={disabled}
              />
            </Grid>

            <Grid item xs={12}>
              <Typography color="textSecondary">
                Fetch live data for the past{" "}
                <Typography css={styles.bolderText} component="span">
                  {liveDataTextOne}
                </Typography>
                .
              </Typography>
            </Grid>
          </Grid>
        </Box>
      ) : null}

      {mode === "Static data" ? (
        <Box component="div" css={styles.width100}>
          <Stack
            css={styles.width100}
            spacing={3}
            direction={{ xs: "column", sm: "row" }}
          >
            <DateAndTimePicker
              css={styles.width100}
              label="Date from"
              value={stateTimeConfigData.dateFrom as Date | null}
              onChange={(val) => handleChangeStaticData("dateFrom", val)}
              disableFuture
              disabled={disabled}
            />
            <DateAndTimePicker
              css={styles.width100}
              label="Date to"
              value={stateTimeConfigData.dateTo as Date | null}
              minDate={
                stateTimeConfigData.dateFrom
                  ? (stateTimeConfigData.dateFrom as Date)
                  : undefined
              }
              onChange={(val) => handleChangeStaticData("dateTo", val)}
              disableFuture
              disabled={disabled}
            />
          </Stack>
        </Box>
      ) : null}

      {mode === "Common periods" ? (
        <Box component="div" css={styles.width100}>
          <Stack
            css={[styles.width100, styles.sectionBreak]}
            spacing={3}
            direction={{ xs: "column", sm: "row" }}
          >
            <Select
              css={styles.widthLimit15}
              selectOptions={dateTimeCommonPeriodOptions}
              label="Period"
              fullWidth
              value={stateTimeConfigData.commonPeriod || ""}
              onChange={(e) =>
                handleChangeCommonPeriodData("commonPeriod", e.target.value)
              }
              disabled={disabled}
            />

            <Collapse
              css={styles.width100}
              in={stateTimeConfigData.commonPeriod?.startsWith("Current")}
            >
              <Select
                css={styles.widthLimit15}
                selectOptions={dateTimeFetchRateOptions}
                label="Fetch rate"
                fullWidth
                value={stateTimeConfigData.commonPeriodFetchRate || ""}
                onChange={(e) =>
                  handleChangeCommonPeriodData("commonPeriodFetchRate", e.target.value)
                }
                disabled={disabled}
              />
            </Collapse>
          </Stack>

          <Typography color="textSecondary">
            Fetch data for
            {periodText.startsWith("yesterday") || periodText.startsWith("today")
              ? " "
              : " the "}
            <Typography css={styles.bolderText} component="span">
              {periodText}
            </Typography>
          </Typography>
        </Box>
      ) : null}
    </Box>
  );
};

export default DateTimeConfiguration;

const getTimeConfigDataText = (timeConfigData: ExcellenceDateTimeConfigurationData) => {
  const periodNumber = timeConfigData.liveDataPeriodNumber || "0";

  const pluralPeriod = periodNumber && +periodNumber > 1 ? "s" : "";

  const periodTime = timeConfigData.liveDataPeriodTime
    ? `${
        periodSymbolMapping[timeConfigData.liveDataPeriodTime as PeriodTimeSymbol]
      }${pluralPeriod}`
    : "_";

  const textOne = `${periodNumber} ${periodTime}`;

  return {
    liveDataTextOne: textOne,
  };
};

const getCommonPeriodText = (
  timeConfigData: ExcellenceDateTimeConfigurationData
): string => {
  const period = timeConfigData.commonPeriod || "_";
  const isTodayYesterday = period === "Today" || period === "Yesterday";

  let start = timeConfigData.commonPeriodStart
    ? formatDateAndTime(
        dateOrStringTransform(timeConfigData.commonPeriodStart, "date"),
        "date"
      )
    : "_";
  let end = timeConfigData.commonPeriodEnd
    ? formatDateAndTime(
        dateOrStringTransform(timeConfigData.commonPeriodEnd, "date"),
        "date"
      )
    : "_";

  return `${period.toLowerCase()}: ${start} ${isTodayYesterday ? "" : `to ${end}`}`;
};

type PeriodStartEndDates = { startDate: Date; endDate: Date };
const getCommonPeriodStartEndDates = (
  commonPeriod: DateTimeCommonPeriod
): PeriodStartEndDates => {
  const currentDate = new Date();

  switch (commonPeriod) {
    case "Today": {
      const todayStartDate = startOfDay(currentDate);
      const todayEndDate = endOfDay(currentDate);

      return {
        startDate: todayStartDate,
        endDate: todayEndDate,
      };
    }
    case "Yesterday": {
      const yesterdayStartDate = startOfDay(subDays(currentDate, 1));
      const yesterdayEndDate = endOfDay(subDays(currentDate, 1));

      return {
        startDate: yesterdayStartDate,
        endDate: yesterdayEndDate,
      };
    }
    case "Current week": {
      const currentWeekStartDate = startOfWeek(currentDate, { weekStartsOn: 1 });
      const currentWeekEndDate = endOfWeek(currentDate, { weekStartsOn: 1 });

      return {
        startDate: currentWeekStartDate,
        endDate: currentWeekEndDate,
      };
    }
    case "Last week": {
      const lastWeekStartDate = startOfWeek(subWeeks(currentDate, 1), {
        weekStartsOn: 1,
      });
      const lastWeekEndDate = endOfWeek(subWeeks(currentDate, 1), { weekStartsOn: 1 });

      return {
        startDate: lastWeekStartDate,
        endDate: lastWeekEndDate,
      };
    }
    case "Current month": {
      const currentMonthStartDate = startOfMonth(currentDate);
      const currentMonthEndDate = endOfMonth(currentDate);

      return {
        startDate: currentMonthStartDate,
        endDate: currentMonthEndDate,
      };
    }
    case "Last month": {
      const lastMonthStartDate = startOfMonth(subMonths(currentDate, 1));
      const lastMonthEndDate = endOfMonth(subMonths(currentDate, 1));

      return {
        startDate: lastMonthStartDate,
        endDate: lastMonthEndDate,
      };
    }
    case "Last quarter": {
      const lastQuarterStartDate = startOfQuarter(subQuarters(currentDate, 1));
      const lastQuarterEndDate = endOfQuarter(subQuarters(currentDate, 1));

      return {
        startDate: lastQuarterStartDate,
        endDate: lastQuarterEndDate,
      };
    }
    case "Current quarter": {
      const currentQuarterStartDate = startOfQuarter(currentDate);
      const currentQuarterEndDate = endOfQuarter(currentDate);

      return {
        startDate: currentQuarterStartDate,
        endDate: currentQuarterEndDate,
      };
    }
    case "Current year": {
      const currentYearStartDate = startOfYear(currentDate);
      const currentYearEndDate = endOfYear(currentDate);

      return {
        startDate: currentYearStartDate,
        endDate: currentYearEndDate,
      };
    }
    case "Last year": {
      const lastYearStartDate = startOfYear(subYears(currentDate, 1));
      const lastYearEndDate = endOfYear(subYears(currentDate, 1));

      return {
        startDate: lastYearStartDate,
        endDate: lastYearEndDate,
      };
    }
  }
};

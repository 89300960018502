import ExcellenceChartFormWrapper from "../EditExcellenceChartForms/ExcellenceChartFormWrapper";
import {
  ChartConfigFormValuesWithTitle,
  ExcellenceChartFormProps,
  GaugeWidgetData,
} from "../EditExcellenceChartForms/excellenceChartFormUtils";
import useTheme from "@mui/material/styles/useTheme";
import { Grid, Typography } from "@mui/material";
import cssLayoutStyles from "../../../Global/Styles/layout";
import cssSpacingStyles from "../../../Global/Styles/spacing";
import { useEffect, useState } from "react";
import { DynamicGridChartOptions } from "../../SmallComponents/DynamicGridLayout.tsx/dynamicGridUtils";
import { GaugeWidgetConfiguration } from "./gaugeWidgetUtils";
import GaugeWidget from "./GaugeWidget";
import EditGaugeWidgetDataSchema from "./EditGaugeWidgetDataSchema";
import EditGaugeWidgetConfig from "./EditGaugeWidgetConfig";
import { ExcellenceChartDataSchema } from "../../../GraphQL/Excellence/graphQLTypesExcellence";
import { useLanguageContext } from "../../../context/LanguageContext";
import useExcellenceChartUnsavedChanges from "../../../Global/Hooks/useExcellenceChartUnsavedChanges";

interface EditGaugeWidgetFormProps extends ExcellenceChartFormProps {
  config: GaugeWidgetConfiguration;
  data: GaugeWidgetData;
}

const EditGaugeWidgetForm: React.FC<EditGaugeWidgetFormProps> = ({
  chartClass,
  config,
  dataSchema,
  data,
  widgetTitle,
  handleSaveChanges,
  parameters,
  handleSetUnsavedChanges,
}) => {
  const theme = useTheme();
  const styles = {
    ...cssLayoutStyles,
    ...cssSpacingStyles(theme),
  };
  const { t } = useLanguageContext();
  const [tabValue, setTabValue] = useState(0);

  const { updatedConfig, setUpdatedConfig, updatedSchema } =
    useExcellenceChartUnsavedChanges<
      ChartConfigFormValuesWithTitle<GaugeWidgetConfiguration>,
      ExcellenceChartDataSchema | null
    >({
      initialConfig: {
        title: widgetTitle,
        ...config,
      },
      initialSchema: dataSchema as ExcellenceChartDataSchema,
      handleSetUnsavedChanges,
    });

  // tab number 0
  const [fieldAlert, setFieldAlert] = useState<string | null>(null);

  // tab number 1
  const [updatedData, setUpdatedData] = useState<GaugeWidgetData>(data);
  const [gaugeDataMaxValue, setGaugeDataMaxValue] = useState<number>(0);
  const [gaugeDataMinValue, setGaugeDataMinValue] = useState<number>(0);
  // const [graphqlFilters, setGraphqlFilters] = useState<GraphqlFilter[]>(
  //   config?.graphqlFilters || []
  // );

  useEffect(() => {
    const { rangeData } = data;
    setGaugeDataMaxValue(Math.max(...rangeData.map((d) => d.value)));
    setGaugeDataMinValue(Math.min(...rangeData.map((d) => d.value)));
  }, [data]);

  const handleSubmit = () => {
    const { title, ...otherConfig } = updatedConfig;
    const updatedChart: DynamicGridChartOptions = {
      gaugeWidget: { config: otherConfig, data: updatedData, dataSchema: updatedSchema },
    };

    // do some field checks
    if (!title) {
      setFieldAlert(t("Title is required"));
    } else {
      // all fields checks have passed
      handleSaveChanges(updatedChart, title);
    }
  };

  return (
    <ExcellenceChartFormWrapper
      alertMessage={fieldAlert}
      handleSubmit={handleSubmit}
      tabValue={tabValue}
      setTabValue={setTabValue}
      disabledDataTab
    >
      <Grid container spacing={2}>
        <Grid item xs={12} sm={5.5}>
          {tabValue === 0 ? (
            <EditGaugeWidgetConfig
              config={updatedConfig}
              setUpdatedConfig={setUpdatedConfig}
              gaugeDataMaxValue={gaugeDataMaxValue}
              gaugeDataMinValue={gaugeDataMinValue}
            />
          ) : null}

          {tabValue === 1 ? (
            <EditGaugeWidgetDataSchema
              parameters={parameters}
              dataSchema={updatedSchema}
              setUpdatedData={setUpdatedData}
            />
          ) : null}
        </Grid>

        <Grid item xs={12} sm={6.5}>
          <Typography css={styles.labelBreak} variant="h3">
            {updatedConfig.title}
          </Typography>
          <GaugeWidget
            css={chartClass}
            configuration={updatedConfig}
            data={updatedData}
          />
        </Grid>
      </Grid>
    </ExcellenceChartFormWrapper>
  );
};

export default EditGaugeWidgetForm;

import { Box, Stack, Tooltip, Typography, useTheme } from "@mui/material";
import { LegendItem } from "./excellenceUtils";
import cssLayoutStyles from "../../Global/Styles/layout";
import cssSpacingStyles from "../../Global/Styles/spacing";
import cssComponentsStyles from "../../Global/Styles/components";
import { BarDownSampledCombinedData, CHART_COMBINED_KEY } from "./BarChart/barChartUtils";
import { memo, useMemo } from "react";
import Button from "../MaterialUI/Button";
import { t } from "i18next";

interface CustomLegendProps {
  items: LegendItem[];
  width: number;
  visibleItems: string[];
  onToggleVisibility: (key: string) => void;
  direction?: "row" | "column";
  maxHeight?: string;
  combinedSegments?: BarDownSampledCombinedData;
}

export const CustomLegend: React.FC<CustomLegendProps> = memo(
  ({
    items,
    width,
    visibleItems,
    onToggleVisibility,
    direction = "column",
    maxHeight = "200px",
    combinedSegments = [],
  }) => {
    const combinedSegmentsArr = useMemo(
      () =>
        combinedSegments?.[0]?.data?.length
          ? combinedSegments[0].data.map((item) => item.label)
          : [],
      [combinedSegments]
    );

    return (
      <Box
        component="div"
        style={{
          overflowY: direction === "column" ? "auto" : "hidden",
          overflowX: direction === "row" ? "hidden" : "auto",
          maxHeight: maxHeight,
          maxWidth: `${width}px`,
          paddingBottom: "4rem",
        }}
      >
        <Stack
          spacing={1}
          justifyContent="center"
          direction={direction}
          flexWrap={direction === "row" ? "wrap" : "nowrap"}
        >
          {items.map((item) => {
            const isVisible = visibleItems.includes(item.label);

            return (
              <Tooltip
                key={`${item.label}-${item.color}`}
                arrow
                title={
                  combinedSegmentsArr.length && item.label === CHART_COMBINED_KEY ? (
                    <Stack spacing={1}>
                      <Typography variant="body1">{item.label}</Typography>
                      <Stack spacing={0.5}>
                        {combinedSegmentsArr.map((seg) => (
                          <Typography variant="body2" key={seg}>
                            {seg}
                          </Typography>
                        ))}
                      </Stack>
                    </Stack>
                  ) : (
                    item.label
                  )
                }
              >
                <Box
                  key={item.label}
                  onClick={() => onToggleVisibility(item.label)}
                  component="div"
                  style={{
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                    opacity: isVisible ? 1 : 0.3,
                    marginRight: direction === "row" ? 2 : "0",
                  }}
                >
                  <Box
                    component="div"
                    mr={1}
                    style={{
                      width: "14px",
                      height: "14px",
                      borderRadius: "100%",
                      backgroundColor: item.color,
                    }}
                  />
                  <Typography
                    style={{
                      flex: 1,
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                    }}
                    variant="caption"
                  >
                    {item.label}
                  </Typography>
                </Box>
              </Tooltip>
            );
          })}
        </Stack>
      </Box>
    );
  }
);

interface CustomTooltipProps<T> {
  data: T;
  renderContent: (data: T) => JSX.Element;
  tooltipColor: string;
}

export const CustomTooltip = <T,>({
  data,
  renderContent,
  tooltipColor,
}: CustomTooltipProps<T>) => {
  const theme = useTheme();
  const styles = {
    ...cssLayoutStyles,
    ...cssSpacingStyles(theme),
    ...cssComponentsStyles(theme),
  };

  return (
    <Box
      component="div"
      css={[styles.card, styles.flex, styles.leftRightPadding2, styles.tooltipStyle]}
      gap={1}
    >
      <Box
        component="div"
        sx={{
          width: 14,
          height: 14,
          backgroundColor: tooltipColor,
          borderRadius: "50%",
          mt: 0.5,
        }}
      />
      {renderContent(data)}
    </Box>
  );
};

export const ChartNotRendered: React.FC = () => {
  return (
    <Stack
      sx={{ width: "100%" }}
      p={2}
      spacing={2}
      alignItems="center"
      justifyContent="center"
    >
      <Typography variant="h4">
        Your data configuration has returned too many data points.
      </Typography>
      <Typography variant="h4">
        Please use filters, change the data configuration, or view as a table.
      </Typography>
    </Stack>
  );
};

interface RemoveFiltersWarningProps {
  confirmChangeConnection: () => void;
  onClose: () => void;
}

export const RemoveFiltersWarning: React.FC<RemoveFiltersWarningProps> = ({ 
  confirmChangeConnection, 
  onClose 
}) => {
  return (
    <Stack spacing={3} alignItems="center" justifyContent="center">
      <Typography variant="body1">
        {t(
          "If you click 'Proceed' all active filters on the current connection will be removed."
        )}
      </Typography>

      <Stack
        spacing={3}
        direction="row"
        alignItems="center"
        justifyContent="center"
      >
        <Button
          color="error"
          onClick={confirmChangeConnection}
        >
          {t("Proceed and lose changes")}
        </Button>
        <Button onClick={onClose}>{t("Cancel")}</Button>
      </Stack>
    </Stack>
  );
};

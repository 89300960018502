import {
  Box,
  IconButton,
  Stack,
  Theme,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import cssLayoutStyles from "../../../Global/Styles/layout";
import cssSpacingStyles from "../../../Global/Styles/spacing";
import { ExcellenceParameter } from "../../../GraphQL/Excellence/graphQLTypesExcellence";
import { css } from "@emotion/react";
import { useEffect, useState } from "react";
import DateAndTimePicker from "../../MaterialUI/DateTimePickers/DateAndTimePicker";
import Autocomplete from "../../MaterialUI/FormFields/Autocomplete";
import { isValid } from "date-fns";
import {
  AutocompleteGroupedOption,
  AutocompleteOption,
  FormStatuses,
  SelectOption,
} from "../../../Global/Types/commonTypes";
import { ExcellenceParamMapping } from "../EditExcellenceChartForms/excellenceChartFormUtils";
import AddIcon from "@mui/icons-material/Add";
import AutocompleteGrouped from "../../MaterialUI/FormFields/AutocompleteGrouped";
import CloseIcon from "@mui/icons-material/Close";
import { handleGetSelectOption } from "../../../Global/Utils/commonFunctions";
import { useLanguageContext } from "../../../context/LanguageContext";
import Select from "../../MaterialUI/FormFields/Select";
import { PieAggregationFunction } from "../PieChart/pieChartTypes";
import {
  LineChartDataSchema,
  LineChartXParameter,
  LineChartYParameter,
} from "./lineChartTypes";
import {
  GraphqlFilter,
  handleExcellenceParamToGraphqlFilters,
} from "../../SmallComponents/GraphqlFilters/graphqlFiltersUtils";
import GraphqlFilters from "../../SmallComponents/GraphqlFilters/GraphqlFilters";
import Modal from "../../MaterialUI/Modal";
import { FiltersModalLayoutData } from "../excellenceUtils";
import { RemoveFiltersWarning } from "../ExcellenceSmallComponents";

const AGGREGATE_OPTIONS: SelectOption[] = Object.entries(PieAggregationFunction).map(
  ([key, value]) => ({ description: key, value: value })
);
const AGGREGATE_STRING_OPTIONS: SelectOption[] = [
  { description: "Count", value: PieAggregationFunction.Count },
];

const cssStyles = (theme: Theme) => ({
  autocompleteGroup: css({
    position: "sticky",
    top: "-8px",
    padding: "4px 10px",
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.secondary.light,
  }),
  autocompleteList: css({
    padding: 0,
  }),
});
type ParamRow = AutocompleteGroupedOption | null;
type YAxisParamRows = {
  param: AutocompleteGroupedOption | null;
  aggregation: PieAggregationFunction | "";
};
const EMPTY_PARAM_ROW: YAxisParamRows = {
  param: null,
  aggregation: "",
};

interface EdiLineChartDataSchemaProps {
  parameters: ExcellenceParameter[];
  dataSchema: LineChartDataSchema | null;
  handleUpdateDataSchema: (schema: LineChartDataSchema | null) => void;
  graphqlFilters: GraphqlFilter[];
  setGraphqlFilters: React.Dispatch<React.SetStateAction<GraphqlFilter[]>>;
  initialSetupComplete: boolean;
  setInitialSetupComplete: React.Dispatch<React.SetStateAction<boolean>>;
  paramMapping: ExcellenceParamMapping | null;
}

const EdiLineChartDataSchema: React.FC<EdiLineChartDataSchemaProps> = ({
  parameters,
  dataSchema,
  handleUpdateDataSchema,
  graphqlFilters,
  setGraphqlFilters,
  setInitialSetupComplete,
  paramMapping,
}) => {
  const theme = useTheme();
  const styles = {
    ...cssSpacingStyles(theme),
    ...cssStyles(theme),
    ...cssLayoutStyles,
  };
  const { t } = useLanguageContext();
  // user-selected
  const [dateFrom, setDateFrom] = useState<Date | null>(
    dataSchema?.startTime ? new Date(dataSchema.startTime) : null
  );
  const [dateTo, setDateTo] = useState<Date | null>(
    dataSchema?.endTime ? new Date(dataSchema.endTime) : null
  );
  // options
  const [nodeOptions, setNodeOptions] = useState<AutocompleteGroupedOption[]>([]);
  const [_, setNodeNumbOptions] = useState<AutocompleteGroupedOption[]>([]);
  const [connectionOptions, setConnectionOptions] = useState<SelectOption[]>([]);
  const [appliedFiltersModal, setAppliedFiltersModal] = useState<FiltersModalLayoutData>({
    isOpen: false,
    data: {value: '', description: ''}
  });
  // selected values
  const [selectedConnection, setSelectedConnection] = useState<AutocompleteOption>({
    value: '',
    description: ''
  });
  const [xAxisParam, setXAxisParam] = useState<ParamRow>(null);
  const [paramRows, setParamRows] = useState<YAxisParamRows[]>([]);

  // system-used
  const [formStatus, setFormStatus] = useState<FormStatuses>(null);
  const [reUpdateSchema, setReUpdateSchema] = useState<boolean>(false);

  useEffect(() => {
    if (graphqlFilters.length && !paramRows.length) {
      const selectedConnections: AutocompleteOption[] = Array.from(
        new Map(
          graphqlFilters.map((item) => [item.containerId, {
            value: item.containerId,
            description: item.containerName
          }])
        ).values()
      );
      setSelectedConnection(selectedConnections[0]);
      setParamRows([EMPTY_PARAM_ROW]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (dataSchema && paramMapping) {
      const selectedConnectionState: string =
        (dataSchema.y.length && dataSchema.y[0].container) ||
        (dataSchema.x && dataSchema?.x.container);

      // const selectedConnectionsSet: Set<string> = new Set();
      let paramX: ParamRow = null;

      // for (const item of dataSchema.y) {
      //   selectedConnectionsSet.add(item.container);
      // }
      if (dataSchema.x) {
        // selectedConnectionsSet.add(dataSchema.x.container);
        paramX = {
          value: dataSchema.x.parameterId,
          description: dataSchema.x.parameterId,
          groupName: dataSchema.x.container,
        };
      }

      // const selectedConnectionsArr: SelectOption[] = Array.from(
      //   selectedConnectionsSet
      // ).map((item) => {
      //   const conn = Object.values(paramMapping).find(
      //     (param) => param.connection === item
      //   );
      //   const connName = conn?.connectionName || item;

      //   return {
      //     value: item,
      //     description: connName,
      //   };
      // });

      const nodeParams: YAxisParamRows[] = dataSchema.y.map((item) => ({
        param: {
          value: item.parameterId,
          groupName: item.container,
          description: paramMapping?.[item.parameterId].paramName || item.parameterId,
        },
        aggregation: item.aggregation,
      }));

      const conn = Object.values(paramMapping).find(
        (param) => param.connection === selectedConnectionState
      );
      const connName = conn?.connectionName || selectedConnectionState;

      setSelectedConnection({
        value: selectedConnectionState,
        description: connName,
      });
      setParamRows(nodeParams);
      setXAxisParam(paramX);
      setInitialSetupComplete(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paramMapping]);

  useEffect(() => {
    const connectionsRecord: Record<string, string> = {};
    parameters.forEach((item) => {
      connectionsRecord[item.container] = item.containerName;
    });

    const allConnectionOptions: SelectOption[] = Object.entries(connectionsRecord).map(
      ([key, value]) => ({
        value: key,
        description: value,
      })
    );

    handleGetSelectOption([...new Set(parameters.map((item) => item.container))]);
    setConnectionOptions(allConnectionOptions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parameters]);

  useEffect(() => {
    if (paramMapping) {
      const paramOptions = handleGetParamOptions(parameters, selectedConnection);
      const numbOnlyOptions = paramOptions.filter(
        (node) =>
          paramMapping?.[node.value]?.type && paramMapping[node.value].type === "number"
      );

      setNodeOptions(paramOptions);
      setNodeNumbOptions(numbOnlyOptions);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedConnection, paramMapping]);

  useEffect(() => {
    const startTime = dateFrom && isValid(dateFrom) ? dateFrom.toISOString() : null;
    const endTime = dateTo && isValid(dateTo) ? dateTo.toISOString() : null;
    const groupParams: LineChartYParameter[] = [];
    const xParam: LineChartXParameter | null =
      paramMapping && xAxisParam?.value
        ? {
          parameterId: xAxisParam.value,
          container: paramMapping[xAxisParam.value].connection,
        }
        : null;

    for (const row of paramRows) {
      if (
        row?.param?.value &&
        paramMapping?.[row.param.value]?.connection &&
        row.aggregation
      ) {
        groupParams.push({
          parameterId: row.param.value,
          container: paramMapping[row.param.value].connection,
          aggregation: row.aggregation,
        });
      }
    }

    if (groupParams.length && xParam) {
      const updatedSchema: LineChartDataSchema = {
        startTime,
        endTime,
        x: xParam,
        y: groupParams,
      };
      handleUpdateDataSchema(updatedSchema);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reUpdateSchema]);

  const onDateRangeChange = async (val: Date | null, type: "dateTo" | "dateFrom") => {
    try {
      let fullDataRow: boolean = false;

      if (type === "dateTo") {
        setDateTo(val);
        fullDataRow = !!dateFrom && isValid(dateFrom);
      } else {
        setDateFrom(val);
        fullDataRow = !!dateTo && isValid(dateTo);
      }

      if (val && isValid(val) && fullDataRow) {
        setReUpdateSchema((prev) => !prev);
      }
    } catch (err) {
      console.log("err onDateRangeChange() ", err);
      setFormStatus("error");
    }
  };

  const handleOnConnectionChange = (val: SelectOption) => {
    if ( !val && graphqlFilters.length ) {
      setAppliedFiltersModal({isOpen: true, data: {value: "", description: ""}});
    } else if (selectedConnection.value !== val.value && graphqlFilters.length) {
      setAppliedFiltersModal({isOpen: true, data: {value: val.value, description: val.description}});
    } else {
      confirmChangeConnection(val);
    }
  };

  const handleOnXAxisChange = (val: AutocompleteGroupedOption) => {
    setXAxisParam(val);
    setReUpdateSchema((prev) => !prev);
  };

  const handleAddNewParam = () => {
    setParamRows((prev) => [...prev, EMPTY_PARAM_ROW]);
  };

  const handleOnNodeChange = (val: AutocompleteGroupedOption, index: number) => {
    const newParamRows = paramRows.map((item, itemIndex) => {
      if (itemIndex === index && val?.value) {
        return {
          param: val,
          aggregation: item.aggregation,
        };
      }

      return item;
    });

    setParamRows(newParamRows);
    setReUpdateSchema((prev) => !prev);
  };

  const handleOnAggregateChange = (val: string, index: number) => {
    let fullDataRow: boolean = false;
    setParamRows((prev) => {
      const result = prev.map((item, itemIndex) => {
        if (itemIndex === index) {
          fullDataRow = !!item.param;
          return {
            ...item,
            aggregation: val as PieAggregationFunction,
          };
        }

        return item;
      });
      return result;
    });

    if (fullDataRow) {
      setReUpdateSchema((prev) => !prev);
    }
  };

  const handleRemoveParamRow = (index: number) => {
    const newParamRows = paramRows.filter((_, rowIndex) => index !== rowIndex);
    setParamRows(newParamRows);
    setReUpdateSchema((prev) => !prev);
  };

  const confirmChangeConnection = (val: SelectOption) => {
    setSelectedConnection(val || { value: "", description: "" });
    setReUpdateSchema((prev) => !prev);
    if (!paramRows.length) {
      setParamRows([EMPTY_PARAM_ROW]);
    }
    setGraphqlFilters([]);
    setAppliedFiltersModal({isOpen: false, data: {value: '', description: ''}});
  }

  if (!parameters?.length) {
    return (
      <Box component="div">
        <Typography variant="h3" color="error">
          {t("You don't have access to any active connections")}
        </Typography>
      </Box>
    );
  }

  if (formStatus === "error") {
    return (
      <Box component="div">
        <Typography variant="h3" color="error">
          {t("Something went wrong")}
        </Typography>
      </Box>
    );
  }

  return (
    <Stack
      css={styles.contentBreak}
      spacing={3}
      alignItems="center"
      justifyContent="center"
    >
      <Stack css={styles.width100} spacing={3} direction={{ xs: "column", sm: "row" }}>
        <DateAndTimePicker
          css={styles.width100}
          label={t("Date from")}
          value={dateFrom}
          onChange={(val) => onDateRangeChange(val, "dateFrom")}
        />
        <DateAndTimePicker
          css={styles.width100}
          label={t("Date to")}
          value={dateTo}
          onChange={(val) => onDateRangeChange(val, "dateTo")}
        />
      </Stack>

      <Stack css={styles.width100} spacing={3} direction={{ xs: "column", sm: "row" }}>
        <Autocomplete
          css={styles.width100}
          label={t("Selected Connection")}
          options={connectionOptions}
          value={selectedConnection}
          handleOnChange={handleOnConnectionChange}
          disabled={formStatus === "loading"}
        />

        <Box css={[styles.flex, styles.flexAlignItemsEnd]} component="div">
          <GraphqlFilters
            key={graphqlFilters.length}
            data={handleExcellenceParamToGraphqlFilters(parameters)}
            graphqlFilters={graphqlFilters}
            setGraphqlFilters={setGraphqlFilters}
            selectedContainers={[selectedConnection.value]}
          />
        </Box>
      </Stack>

      <Stack css={styles.width100} direction="row" gap={3} alignItems="center">
        <AutocompleteGrouped
          css={styles.width100}
          label="Parameter X-axis"
          options={nodeOptions}
          value={xAxisParam}
          handleOnChange={handleOnXAxisChange}
          disabled={formStatus === "loading"}
        />
      </Stack>

      {paramRows.length ? (
        <Stack
          css={[styles.width100, styles.overflowYAuto]}
          sx={{ maxHeight: "250px" }}
          gap={3}
        >
          {paramRows.map((item, index) => (
            <Stack
              style={{ width: "99%" }}
              key={`item-index-${index}`}
              direction="row"
              gap={3}
              alignItems="center"
            >
              <AutocompleteGrouped
                css={styles.width100}
                label={`${t("Parameter Y-axis")} ${index + 1}`}
                options={nodeOptions}
                // options={nodeNumbOptions}
                value={item.param}
                handleOnChange={(e: AutocompleteGroupedOption) =>
                  handleOnNodeChange(e, index)
                }
                disabled={formStatus === "loading"}
              />
              <Select
                selectOptions={
                  paramMapping?.[item.param?.value || ""]?.type === "string"
                    ? AGGREGATE_STRING_OPTIONS
                    : AGGREGATE_OPTIONS
                }
                label={`${t("Aggregate Function")} ${index + 1}`}
                value={item.aggregation}
                onChange={(e) => handleOnAggregateChange(e.target.value, index)}
                disabled={formStatus === "loading" || !item.param?.value}
              />
              {index === 0 ? (
                <Tooltip title={t("Add New Parameter")}>
                  <IconButton css={styles.marginLeftAuto} onClick={handleAddNewParam}>
                    <AddIcon />
                  </IconButton>
                </Tooltip>
              ) : (
                <Tooltip title={t("Remove Parameter")}>
                  <IconButton
                    css={styles.marginLeftAuto}
                    onClick={() => handleRemoveParamRow(index)}
                  >
                    <CloseIcon />
                  </IconButton>
                </Tooltip>
              )}
            </Stack>
          ))}
        </Stack>
      ) : null}

      <Modal
        open={appliedFiltersModal.isOpen}
        onClose={() => setAppliedFiltersModal(prev => ({ ...prev, isOpen: false }))}
        fullWidth
        label={t("Warning")}
      >
        <RemoveFiltersWarning
          confirmChangeConnection={() => confirmChangeConnection(appliedFiltersModal.data)}
          onClose={() => setAppliedFiltersModal(prev => ({ ...prev, isOpen: false }))}
        />
      </Modal>
    </Stack>
  );
};

export default EdiLineChartDataSchema;

const handleGetParamOptions = (
  parameters: ExcellenceParameter[],
  selectedConnection: SelectOption
): AutocompleteGroupedOption[] => {
  const result =
    parameters
      .map((item) => ({
        groupName: item.container,
        value: item.id,
        description: item.name,
      }))
      .filter((node) =>
        // selectedConnectionsArr.some((sub) => sub.value === node.groupName)
        node.groupName === selectedConnection.value
      ) || [];

  const resultWithGroupNames = result.map((item) => {
    // const found = selectedConnectionsArr.find((conn) => conn.value === item.groupName);
    const found = selectedConnection.value === item.groupName ? selectedConnection : null;
    const name = found?.description || item.groupName;
    return {
      ...item,
      groupName: name,
    };
  });

  return resultWithGroupNames;
};

import { List, ListItem, ListItemText, Divider, Typography } from "@mui/material";
import { useTranslateFields } from "../../../Global/Hooks/useTranslations";

const historyItems = [
  { primaryText: "Object changed locations", timestamp: "08/12/2023, 14:42:26" },
  { primaryText: "Object title changed", timestamp: "08/12/2023, 14:42:26" },
  { primaryText: "Object created", timestamp: "08/12/2023, 14:42:26" },
  { primaryText: "Object changed locations", timestamp: "08/12/2023, 14:42:26" },
  { primaryText: "Object title changed", timestamp: "08/12/2023, 14:42:26" },
  { primaryText: "Object created", timestamp: "08/12/2023, 14:42:26" },
  { primaryText: "Object changed locations", timestamp: "08/12/2023, 14:42:26" },
  { primaryText: "Object title changed", timestamp: "08/12/2023, 14:42:26" },
  { primaryText: "Object created", timestamp: "08/12/2023, 14:42:26" },
  { primaryText: "Object changed locations", timestamp: "08/12/2023, 14:42:26" },
  { primaryText: "Object title changed", timestamp: "08/12/2023, 14:42:26" },
  { primaryText: "Object created", timestamp: "08/12/2023, 14:42:26" },
  { primaryText: "Object changed locations", timestamp: "08/12/2023, 14:42:26" },
  { primaryText: "Object title changed", timestamp: "08/12/2023, 14:42:26" },
  { primaryText: "Object created", timestamp: "08/12/2023, 14:42:26" },
];

interface HistoryTrackingModalLayoutProps {
  projectId: string;
}

const HistoryTrackingModalLayout: React.FC<HistoryTrackingModalLayoutProps> = () => {
  return (
    <List css={{ height: "400px", overflowY: "auto" }}>
      {useTranslateFields(historyItems, ["primaryText"]).map((item, index) => (
        <>
          <ListItem key={`${index}-${item.timestamp}`}>
            <ListItemText primary={item.primaryText} />
            <Typography>{item.timestamp}</Typography>
          </ListItem>
          <Divider variant="middle" />
        </>
      ))}
    </List>
  );
};

export default HistoryTrackingModalLayout;

import { SelectOption } from "../../Global/Types/commonTypes";

export type BarLegendData = {
  formattedKeys: {
    keys: string[];
    indexBy: string;
  };
  legend: LegendItem[];
  colors: string[];
  longestLegend: number;
};

export type LegendItem = {
  label: string;
  color: string;
};

export interface FiltersModalLayoutData {
  isOpen: boolean;
  data: SelectOption;
}

export const getBarKeysAndLegend = (
  datum: { [key: string]: string | number } | undefined,
  colors: string[],
  indexBy?: string
): BarLegendData | undefined => {
  if (!datum) {
    return undefined;
  }
  const formattedKeys = Object.entries(datum).reduce(
    (acc, curr) => {
      const [key, value] = curr;

      if (indexBy && key === indexBy) {
        return {
          ...acc,
          indexBy: key,
        };
      }

      const isNotNumber = isNaN(+value);
      if (isNotNumber) {
        return {
          ...acc,
          indexBy: key,
        };
      }
      return {
        ...acc,
        keys: [...acc.keys, key],
      };
    },
    {
      indexBy: "",
      keys: [],
    } as { keys: string[]; indexBy: string }
  );

  let longestLegend = 0;
  formattedKeys.keys.forEach((item) => {
    if (item.length > longestLegend) {
      longestLegend = item.length;
    }
  });

  const legend = formattedKeys.keys.map((label, index) => {
    return {
      label: label,
      color: colors[index % colors.length],
    };
  });

  return { formattedKeys, legend, colors, longestLegend };
};

export const downSampleGenericArrayData = (
  data: any[], // Generic array that can contain any data structure
  maxDataPoints: number
): any[] => {
  const dataLength = data.length;

  if (dataLength <= maxDataPoints) {
    return data;
  }
  const elementsToRemove = dataLength - maxDataPoints;

  const stepSize = Math.ceil((dataLength - 2) / elementsToRemove);

  const downSampledData = [];

  downSampledData.push(data[0]);

  data.forEach((item, index) => {
    if (index !== 0 && index !== dataLength - 1 && (index - 1) % stepSize === 0) {
      downSampledData.push(item);
    }
  });

  downSampledData.push(data[dataLength - 1]);

  if (downSampledData.length > maxDataPoints) {
    downSampledData.splice(1, downSampledData.length - maxDataPoints);
  }

  return downSampledData;
};

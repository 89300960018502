import { useState } from "react";
import { Grid, IconButton, Stack, Typography } from "@mui/material";
import BasicTable from "../../MaterialUI/BasicTable/BasicTable";
import Checkbox from "../../MaterialUI/FormFields/Checkbox";
import { UserPermissionType, UserPrivilege } from "../../../context/authContextUtils";
import {
  findExcellencePermissionChanges,
  getShareDashboardModalLabel,
  updateExcellencePermissions,
} from "./excellenceUtils";
import Autocomplete from "../../MaterialUI/FormFields/Autocomplete";
import {
  AutocompleteOption,
  FormStatuses,
  SelectOption,
} from "../../../Global/Types/commonTypes";
import {
  DeleteExcellenceUserRolePermissions,
  GetExcellenceUsersPrivileges,
} from "../../../Api/Excellence/apiExcellenceSnippets";
import { getQueryExcellenceUsersWithPermissions } from "../../../Api/Excellence/apiExcellenceGetQueries";
import { useAuthedContext } from "../../../context/AuthContext";
import callApi from "../../../Api/callApi";
import { PostQueryCreateDashboardPermission } from "../../../Api/Excellence/apiExcellenceInputs";
import Button from "../../MaterialUI/Button";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { deleteExcellenceDasboardPermission } from "../../../Api/Excellence/apiExcellencePostQueries";
import Modal from "../../MaterialUI/Modal";
import { ModalLayoutData } from "../../SmallComponents/TableGrid/CellActionButton";
import cssLayoutStyles from "../../../Global/Styles/layout";
import { useLanguageContext } from "../../../context/LanguageContext";
import { useTranslateFields } from "../../../Global/Hooks/useTranslations";

interface SharedUsersTableProps {
  addedUsersPermissions: UserPermissionType[];
  setAddedPermissions: React.Dispatch<React.SetStateAction<UserPermissionType[]>>;
  setChangedPermissions: React.Dispatch<
    React.SetStateAction<PostQueryCreateDashboardPermission[]>
  >;
  initialPermissions: UserPermissionType[];
  dashboardId: string;
  setFormStatus: React.Dispatch<React.SetStateAction<FormStatuses>>;
  setAlertMessage: React.Dispatch<React.SetStateAction<string | null>>;
  fetchDashboardPermissions: () => void;
  searchTerm: string;
  setSearchTerm: React.Dispatch<React.SetStateAction<string>>;
  selectedUser: AutocompleteOption | null;
  setSelectedUser: React.Dispatch<React.SetStateAction<AutocompleteOption | null>>;
  setUnsavedEditChanges: React.Dispatch<React.SetStateAction<boolean>>;
}

const SharedUsersTable: React.FC<SharedUsersTableProps> = ({
  addedUsersPermissions,
  setAddedPermissions,
  setChangedPermissions,
  initialPermissions,
  dashboardId,
  setFormStatus,
  setAlertMessage,
  fetchDashboardPermissions,
  searchTerm,
  setSearchTerm,
  selectedUser,
  setSelectedUser,
  setUnsavedEditChanges,
}) => {
  const { t } = useLanguageContext();
  const styles = { ...cssLayoutStyles };
  const { setAuthedUser } = useAuthedContext();
  const [usersOptions, setUsersOptions] = useState<SelectOption[]>([]);
  const [openInfo, setOpenInfo] = useState<boolean>(false);
  const [modalData, setModalData] = useState<ModalLayoutData | null>(null);
  const filteredData = formatUserDataForTable(
    (addedUsersPermissions as UserPermissionType[]).filter((user) =>
      user.username.toLowerCase().includes(searchTerm.toLowerCase())
    )
  );

  const handleSearchChange = (inputValue: string) => {
    setSearchTerm(inputValue);
  };

  const fetchUsers = async (searchQuery: string) => {
    const usersData = await callApi<GetExcellenceUsersPrivileges>({
      query: getQueryExcellenceUsersWithPermissions(searchQuery),
      auth: { setAuthedUser },
    });
    const options: SelectOption[] = usersData.map((user) => ({
      value: user.user_id,
      description: user.username,
    }));
    setUsersOptions(options);
  };

  const handlePrivilegeToggle = (identifier: string, privilege: UserPrivilege) => {
    setAddedPermissions((prevUsers: UserPermissionType[]) => {
      const updatedUsers = updateExcellencePermissions(
        prevUsers,
        identifier,
        privilege,
        "username"
      );

      const changedUserPermissions = findExcellencePermissionChanges(
        initialPermissions,
        updatedUsers,
        "user_id"
      );
      setUnsavedEditChanges(true);
      setChangedPermissions(changedUserPermissions);
      return updatedUsers;
    });
  };

  const hasPermissions = selectedUser
    ? addedUsersPermissions.some((perm) => perm.user_id === selectedUser.value)
    : false;

  const handleAddUser = () => {
    if (selectedUser && !hasPermissions) {
      const newUserPermissions: UserPermissionType = {
        id: "",
        user_id: selectedUser.value,
        username: selectedUser.description,
        dashboard_id: dashboardId,
        view: true,
        add: false,
        edit: false,
        delete: false,
        share: false,
      };

      setAddedPermissions((prevUsers) => [newUserPermissions, ...prevUsers]);
      setUnsavedEditChanges(true);

      handlePrivilegeToggle(selectedUser.description, "view");
    }
  };

  const handleRemovePermissions = async (permissionId: string) => {
    setFormStatus("loading");
    setAlertMessage(t("Loading..."));

    try {
      await callApi<DeleteExcellenceUserRolePermissions>({
        query: deleteExcellenceDasboardPermission(dashboardId, permissionId),
        auth: { setAuthedUser },
      });

      setUnsavedEditChanges(false);
      setOpenInfo(false);
      fetchDashboardPermissions();
      setFormStatus("success");
      setAlertMessage(t("Seccessfilly deleted user permissions"));
    } catch (error) {
      console.log("There was an error deleting user permissions ", error);
      setFormStatus("error");
      setAlertMessage(t("Something went wrong"));
    }
  };

  return (
    <>
      <Grid container spacing={2} mb={2}>
        <Grid item xs={12}>
          <Grid container alignItems="flex-end" spacing={2}>
            <Grid item xs={5}>
              <Autocomplete
                options={usersOptions}
                label={t("User")}
                value={selectedUser}
                handleOnChange={(val: AutocompleteOption) => {
                  setSelectedUser(val);
                }}
                onInputChange={(_, inputValue) => {
                  handleSearchChange(inputValue);
                  if (inputValue.length >= 3) {
                    fetchUsers(inputValue);
                  }
                }}
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    event.preventDefault();
                  }
                }}
              />
            </Grid>

            <Grid item xs={0.5}>
              {selectedUser && !hasPermissions && (
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={handleAddUser}
                  fullWidth
                >
                  <AddOutlinedIcon />
                </Button>
              )}
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          {hasPermissions ? (
            <Typography variant="body2">
              {t(
                "This user already has added permissions. You can edit them from the Shared Users Table."
              )}
            </Typography>
          ) : selectedUser ? (
            <Typography variant="body2">
              {t(
                "This user has no permissions yet. Add them to manage their permissions."
              )}
            </Typography>
          ) : null}
        </Grid>
      </Grid>

      <BasicTable
        data={{
          rows: filteredData,
          columns: useTranslateFields(USER_COLUMNS, ["label"]),
        }}
        renderCell={(row, column) => {
          if (["view", "add", "edit", "delete", "share"].includes(column.id)) {
            if (column.id === "view") {
              return <Checkbox checked={true} disabled={true} />;
            }
            return (
              <Checkbox
                checked={row[column.id as keyof typeof row] as boolean}
                onChange={() =>
                  handlePrivilegeToggle(row.username, column.id as UserPrivilege)
                }
              />
            );
          }
          if (column.id === "deleteAction") {
            return (
              <IconButton
                onClick={() => {
                  setOpenInfo(true);
                  setModalData({ type: "deletePermissions", data: row.deleteAction });
                }}
              >
                <DeleteOutlineOutlinedIcon />
              </IconButton>
            );
          }
          return row[column.id as keyof typeof row];
        }}
        defaultOrderBy={"username"}
        emptyTableMessage={t("No users shared yet.")}
        dense={true}
        pageRows={5}
      />

      {modalData ? (
        <Modal
          open={openInfo}
          fullWidth
          label={getShareDashboardModalLabel(modalData)}
          onClose={() => setOpenInfo(false)}
        >
          {modalData.type === "deletePermissions" ? (
            <Stack spacing={2}>
              <Typography variant="body1">
                {t("Are you sure you want to delete user permissions?")}
              </Typography>

              <Stack css={styles.width100} alignItems="center" justifyContent="center">
                <Button
                  css={styles.widthLimit20}
                  color="error"
                  onClick={() => handleRemovePermissions(modalData.data)}
                >
                  {t("Confirm Deletion")}
                </Button>
              </Stack>
            </Stack>
          ) : null}
        </Modal>
      ) : null}
    </>
  );
};

export default SharedUsersTable;

const USER_COLUMNS = [
  { id: "username", label: "Username", width: "10%" },
  { id: "view", label: "View", width: "10%" },
  { id: "add", label: "Add", width: "10%" },
  { id: "edit", label: "Edit", width: "10%" },
  { id: "delete", label: "Delete", width: "10%" },
  { id: "share", label: "Share", width: "10%" },
  { id: "deleteAction", label: "", width: "2%" },
];

const formatUserDataForTable = (users: UserPermissionType[]) => {
  return users.map((user) => ({
    username: user.username,
    view: user.view,
    add: user.add,
    edit: user.edit,
    delete: user.delete,
    share: user.share,
    deleteAction: user.id,
  }));
};

import { Theme } from "@mui/material";
import { ExcellenceChartDataSchema } from "../../../GraphQL/Excellence/graphQLTypesExcellence";
import {
  ChartConfigFormValuesWithTitle,
  ChartSectionsNumberScheme,
  CustomSetSequenceChartColorScheme,
  GaugeChartColorScheme,
  GaugeWidgetData,
} from "../EditExcellenceChartForms/excellenceChartFormUtils";
import { getChartColors } from "../nivoTheme";
import { GraphqlFilter } from "../../SmallComponents/GraphqlFilters/graphqlFiltersUtils";

export type GaugeDatum = {
  id: string;
  label: string;
  value: number;
  index: number;
};

export type GaugeWidgetConfigAndData = {
  config: GaugeWidgetConfiguration;
  dataSchema: ExcellenceChartDataSchema | null;
  data: GaugeWidgetData;
};

export type SectionRange = {
  min: number | null;
  max: number | null;
};

export type GaugeWidgetConfiguration = {
  enableArcLabels: boolean;
  enableArcLinkLabels: boolean;
  colors: GaugeChartColorScheme;
  colorsSequence: CustomSetSequenceChartColorScheme;
  unitOfMeasure: string;
  sections: ChartSectionsNumberScheme;
  showDataInPercentage: boolean;
  gaugeMinValue: number;
  gaugeMaxValue: number;
  sectionRanges: SectionRange[];
  sectionTitles: string[];
  graphqlFilters?: GraphqlFilter[];
};

export type KeyOfConfigValue =
  keyof ChartConfigFormValuesWithTitle<GaugeWidgetConfiguration>;

export const cleanupErrorsAndMessages = (
  currentSections: number,
  alertMessages: string[],
  setAlertMessages: React.Dispatch<React.SetStateAction<string[]>>,
  valueErrors: { [key: string]: boolean },
  setValueErrors: React.Dispatch<React.SetStateAction<{ [key: string]: boolean }>>
) => {
  const validErrorKeys = Object.keys(valueErrors).filter((key) => {
    const match = key.match(/section(\d+)(Min|Max)Error/);
    return match && Number(match[1]) < currentSections;
  });

  const validErrors = validErrorKeys.reduce<{ [key: string]: boolean }>((acc, key) => {
    acc[key] = valueErrors[key];
    return acc;
  }, {});

  const validMessages = alertMessages.filter((message) => {
    const match = message.match(/Section (\d+)/);
    return match && Number(match[1]) <= currentSections;
  });

  setValueErrors(validErrors);
  setAlertMessages(validMessages);
};

export const calculateTranslationInPercentage = (valueInPercentage: number) => {
  let translateX = -90 + (valueInPercentage - 10);
  let translateY;

  if (valueInPercentage === 0) {
    translateX = -90 + valueInPercentage;
    translateY = -10 - (valueInPercentage + 12);
  } else if (valueInPercentage > 0 && valueInPercentage <= 20) {
    translateY = -10 - (valueInPercentage + 15);
  } else if (valueInPercentage > 20 && valueInPercentage <= 40) {
    translateY = -10 - (valueInPercentage + 10);
  } else if (valueInPercentage > 40 && valueInPercentage < 50) {
    translateY = -10 - valueInPercentage;
  } else if (valueInPercentage === 50) {
    translateY = -10 - valueInPercentage;
  } else if (valueInPercentage > 50 && valueInPercentage <= 60) {
    translateY = -10 - (valueInPercentage - 15);
  } else if (valueInPercentage > 60 && valueInPercentage <= 70) {
    translateY = -10 - (valueInPercentage - 23);
  } else if (valueInPercentage > 70 && valueInPercentage <= 75) {
    translateY = -10 - (valueInPercentage - 33);
  } else if (valueInPercentage > 75 && valueInPercentage <= 80) {
    translateY = -10 - (valueInPercentage - 40);
  } else if (valueInPercentage > 80 && valueInPercentage < 100) {
    translateY = -50 + (valueInPercentage - 77);
  } else if (valueInPercentage === 100) {
    translateX = -90 + (valueInPercentage - 20);
    translateY = -50 + (valueInPercentage - 75);
  }

  return { transform: `translate(${translateX}%, ${translateY}%)` };
};

export const groupAndAverageData = (
  data: GaugeWidgetData,
  configuration: GaugeWidgetConfiguration,
  setCurrentValueInPercentage: React.Dispatch<React.SetStateAction<number>>,
  minValue: number,
  maxValue: number
) => {
  const { currentValue, rangeData } = data;
  const { showDataInPercentage, sectionRanges, sections, sectionTitles } = configuration;
  const titleCount: { [key: string]: number } = {};

  const fullRange = maxValue - minValue;

  const currentValueInPercentage = Number(
    (((currentValue - minValue) / fullRange) * 100).toFixed(2)
  );

  const sectionsNumber = Number(sections);
  let warning = sectionsNumber > rangeData.length;

  const areSectionRangesSet = sectionRanges.some(
    (range) => range.min !== null && range.max !== null
  );

  let sectionedRangeData;

  if (!areSectionRangesSet) {
    const sectionPercentageValue = Number((100 / sectionsNumber).toFixed(2));

    sectionedRangeData = Array.from({ length: sectionsNumber }).map((_, index) => {
      const title = sectionTitles[index] || `Section ${index + 1}`;
      if (!titleCount[title]) {
        titleCount[title] = 0;
      }
      const uniqueId = titleCount[title] === 0 ? title : `${title}-${titleCount[title]}`;
      titleCount[title] += 1;

      const avgValue = sectionPercentageValue / 2;

      return {
        id: uniqueId,
        label: title,
        value: sectionPercentageValue,
        averageValue: avgValue,
        index: index,
      };
    });
  } else {
    sectionedRangeData = sectionRanges.map((section, index) => {
      const sectionMin = section.min ?? minValue;
      const sectionMax = section.max ?? maxValue;

      let countValue = sectionMax - sectionMin;

      if (showDataInPercentage) {
        const sectionFullRange = maxValue - minValue;
        countValue = (countValue / sectionFullRange) * 100;
      }

      if (countValue === 0) {
        warning = true;
      }

      const avgValue = (sectionMin + sectionMax) / 2;

      const title = sectionTitles[index] || `Section ${index + 1}`;
      if (!titleCount[title]) {
        titleCount[title] = 0;
      }
      const uniqueId = titleCount[title] === 0 ? title : `${title}-${titleCount[title]}`;
      titleCount[title] += 1;

      return {
        id: uniqueId,
        label: title,
        value: Number(countValue.toFixed(2)),
        averageValue: avgValue,
        index: index,
      };
    });
  }

  setCurrentValueInPercentage(currentValueInPercentage);
  return {
    data: {
      currentValue: showDataInPercentage ? currentValueInPercentage : currentValue,
      rangeData: sectionedRangeData,
    },
    warning,
  };
};

export const getColorSet = (
  theme: Theme,
  colors: GaugeChartColorScheme,
  colorsSequence: CustomSetSequenceChartColorScheme,
  sections: ChartSectionsNumberScheme
): string[] => {
  if (colors === "Theme colors") {
    return getChartColors();
  } else if (colors === "Custom set") {
    const palettes: ColorPalettesData = colorPalettes(theme);
    const sectionPalette = palettes[sections];
    const sectionColors = sectionPalette
      ? sectionPalette[colorsSequence as keyof typeof sectionPalette]
      : undefined;
    return sectionColors || getChartColors();
  }
  return getChartColors();
};

export function getGaugeWidgetAlertMessage(
  language: string,
  isMinValue: boolean,
  limit: number,
  gaugeDataMaxValue: number
) {
  const message =
    language === "bg"
      ? `${isMinValue ? "Минималната" : "Максималната"} стойност не може да бъде ${
          isMinValue ? "по-малка" : "по-голяма"
        } от ${limit} ${
          isMinValue ? `или повече от ${gaugeDataMaxValue}` : ""
        } поради недостатъчно записи на данни.`
      : language === "en"
      ? `The ${isMinValue ? "minimum" : "maximum"} value cannot ${
          isMinValue ? "be less" : "exceed"
        } than ${limit} ${
          isMinValue ? `or more than ${gaugeDataMaxValue}` : ""
        } due to insufficient data records.`
      : `Der ${isMinValue ? "Mindestwert" : "Höchstwert"} kann nicht ${
          isMinValue ? "weniger sein als" : "überschreiten"
        } ${limit} ${
          isMinValue ? `oder mehr als ${gaugeDataMaxValue}` : ""
        } aufgrund unzureichender Datenaufzeichnungen.`;

  return message;
}

export interface ColorPalettesData {
  "1": {
    green: string[];
    yellow: string[];
    red: string[];
  };
  "2": {
    "green-red": string[];
    "red-green": string[];
  };
  "3": {
    "green-yellow-red": string[];
    "red-yellow-green": string[];
    "red-green-red": string[];
    "green-red-green": string[];
  };
  "4": {
    "green-red": string[];
    "red-green": string[];
  };
  "5": {
    "green-yellow-red": string[];
    "red-yellow-green": string[];
    "red-green-red": string[];
    "green-red-green": string[];
  };
}

export const colorPalettes = (theme: Theme) => ({
  "1": {
    green: [theme.palette.success.main],
    yellow: [theme.palette.warning.main],
    red: [theme.palette.error.main],
  },
  "2": {
    "green-red": [theme.palette.success.main, theme.palette.error.main],
    "red-green": [theme.palette.error.main, theme.palette.success.main],
  },
  "3": {
    "green-yellow-red": [
      theme.palette.success.main,
      theme.palette.warning.main,
      theme.palette.error.main,
    ],
    "red-yellow-green": [
      theme.palette.error.main,
      theme.palette.warning.main,
      theme.palette.success.main,
    ],
    "red-green-red": [
      theme.palette.error.main,
      theme.palette.success.main,
      theme.palette.error.main,
    ],
    "green-red-green": [
      theme.palette.success.main,
      theme.palette.error.main,
      theme.palette.success.main,
    ],
  },
  "4": {
    "green-red": [
      theme.palette.success.main,
      theme.palette.success.light,
      theme.palette.error.light,
      theme.palette.error.main,
    ],
    "red-green": [
      theme.palette.error.main,
      theme.palette.error.light,
      theme.palette.success.light,
      theme.palette.success.main,
    ],
  },
  "5": {
    "green-yellow-red": [
      theme.palette.success.main,
      theme.palette.success.light,
      theme.palette.warning.main,
      theme.palette.error.light,
      theme.palette.error.main,
    ],
    "red-yellow-green": [
      theme.palette.error.main,
      theme.palette.error.light,
      theme.palette.warning.main,
      theme.palette.success.light,
      theme.palette.success.main,
    ],
    "red-green-red": [
      theme.palette.error.main,
      theme.palette.error.light,
      theme.palette.success.main,
      theme.palette.error.light,
      theme.palette.error.main,
    ],
    "green-red-green": [
      theme.palette.success.main,
      theme.palette.success.light,
      theme.palette.error.main,
      theme.palette.success.light,
      theme.palette.success.main,
    ],
  },
});

import {
  BarColumnRadarData,
  ExcellenceChartProps,
} from "../../../../ExcellenceWidgets/EditExcellenceChartForms/excellenceChartFormUtils";
import { RadarChartConfiguration } from "../../../../ExcellenceWidgets/RadarChart/radarChartTypes";
import RadarChart from "../../../../ExcellenceWidgets/RadarChart/RadarChart";
import radarChartDefaultData from "../../../../ExcellenceWidgets/ExcellenceDefaultConfig/radarChartDefaultData";
import { BarChartDataSchema } from "../../../../ExcellenceWidgets/BarChart/barChartTypes";
import { useTheme } from "@mui/material";
import { useLazyQuery } from "@apollo/client";
import { useState, useEffect } from "react";
import cssSpacingStyles from "../../../../../Global/Styles/spacing";
import { FormStatuses } from "../../../../../Global/Types/commonTypes";
import { graphQlQueryExcellenceBarData } from "../../../../../GraphQL/Excellence/graphQLQueriesExcellence";
import Alert from "../../../../MaterialUI/Alert";
import LoadingBackdrop from "../../../../MaterialUI/LoadingBackdrop";
import {
  buildGraphqlFilters,
  GraphqlFilter,
} from "../../../../SmallComponents/GraphqlFilters/graphqlFiltersUtils";

interface ExcellenceRadarChartProps extends ExcellenceChartProps {
  data: BarColumnRadarData | null;
  configuration: RadarChartConfiguration;
  schema: BarChartDataSchema | null;
}

const ExcellenceRadarChart: React.FC<ExcellenceRadarChartProps> = ({
  className,
  data,
  configuration,
  isStatic,
  schema,
  resetChartFetch,
  dataFetchFromParent,
  parameters,
  tempFilters,
  refetchOnFilters,
}) => {
  const theme = useTheme();
  const styles = {
    ...cssSpacingStyles(theme),
  };
  const [updatedData, setUpdatedData] = useState<BarColumnRadarData | null>(data);
  const [fetchStatus, setFetchStatus] = useState<FormStatuses>(null);
  const [chartToUse, setChartToUse] = useState<boolean>(true);
  const [initialFetchDone, setInitialFetchDone] = useState<boolean>(false);

  const [getBarData, { loading: loadingPieData }] = useLazyQuery(
    graphQlQueryExcellenceBarData
  );

  const paramMapping: Record<string, string> | undefined = parameters?.reduce(
    (acc, curr) => {
      return {
        ...acc,
        [curr.id]: curr.name,
      };
    },
    {}
  );

  useEffect(() => {
    (async () => {
      if (initialFetchDone) {
        await handleFetch(tempFilters);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetchOnFilters]);

  useEffect(() => {
    setChartToUse((prev) => !prev);
  }, [updatedData]);

  useEffect(() => {
    (async () => {
      if (!dataFetchFromParent && schema) {
        await handleFetch(configuration?.graphqlFilters);
        setInitialFetchDone(true);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataFetchFromParent, resetChartFetch]);

  useEffect(() => {
    if (dataFetchFromParent) {
      setUpdatedData(data);
    }
  }, [dataFetchFromParent, data]);

  const handleFetch = async (graphqlFilters: GraphqlFilter[] | undefined) => {
    try {
      setFetchStatus("loading");
      const result = await getBarData({
        variables: {
          input: {
            ...schema,
            filters: buildGraphqlFilters(graphqlFilters),
          },
        },
      });
      if (!result.data || result.error) {
        throw new Error(JSON.stringify(result));
      }
      const resultData = result.data.barchartData.chartData;

      setUpdatedData(resultData);
      setFetchStatus(null);
    } catch (err) {
      console.log("err ", err);
      setFetchStatus("error");
    }
  };

  return (
    <>
      {chartToUse ? (
        <RadarChart
          className={className}
          configuration={configuration}
          data={updatedData || radarChartDefaultData}
          isStatic={isStatic}
          indexBy={schema ? "group" : undefined}
          noAnimation
          schema={schema}
          paramMapping={paramMapping}
        />
      ) : null}
      {!chartToUse ? (
        <RadarChart
          className={className}
          configuration={configuration}
          data={updatedData || radarChartDefaultData}
          isStatic={isStatic}
          indexBy={schema ? "group" : undefined}
          noAnimation
          schema={schema}
          paramMapping={paramMapping}
        />
      ) : null}

      {isStatic ? null : <LoadingBackdrop loading={loadingPieData} />}

      <Alert
        css={styles.reverseContentBreak}
        message={"Something went wrong"}
        showAlert={fetchStatus === "error"}
        severity={"error"}
      />
    </>
  );
};

export default ExcellenceRadarChart;

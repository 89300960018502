import { ExcellenceDateTimeConfigurationData } from "../../SmallComponents/DateTimeConfiguration/dateTimeConfigurationUtils";
import { ExcellenceSwitchIncomingData } from "../../SmallComponents/DynamicGridLayout.tsx/dynamicGridExcellenceUtils";
import { DynamicGridChartType } from "../../SmallComponents/DynamicGridLayout.tsx/dynamicGridUtils";
import { TableGridColumnSchema } from "../../SmallComponents/TableGrid/constructTableGrid";
import { BoxPlotData } from "../AdvancedWidgets/BoxPlot/boxPlotTypes";
import {
  SunburstChildren,
  SunburstData,
} from "../AdvancedWidgets/Sunburst/sunburstTypes";
import {
  BarColumnRadarData,
  ExcellenceTableData,
  LineAreaHeatMapScatterPlotData,
  PieDonutChartData,
} from "../EditExcellenceChartForms/excellenceChartFormUtils";
import {
  ExcellenceTableConfigAndData,
  ExcellenceTableDataFormat,
} from "./editExcellenceTabletypes";

type TableRow = Record<string, any>;

// Excellence charts
export const switchPieDataToTable = (
  data: PieDonutChartData
): ExcellenceTableConfigAndData => {
  const cols: TableGridColumnSchema[] = [
    { id: "id", label: "ID", type: "string" },
    { id: "label", label: "Label", type: "string" },
    { id: "value", label: "Value", type: "number" },
  ];
  const rows: TableRow[] = data.map((item) => ({
    id: item.id,
    label: item.label,
    value: item.value,
  }));

  return {
    data: {
      columns: cols,
      rows,
    },
    dataSchema: null,
    config: {
      dataFormat: "pie",
    },
  };
};

export const switchBarRadarDataToTable = (
  data: BarColumnRadarData
): ExcellenceTableConfigAndData => {
  const entries = Object.entries(data[0]);

  const cols: TableGridColumnSchema[] = entries.map((item) => {
    const [key, value] = item;
    const col: TableGridColumnSchema = {
      id: key,
      label: key,
      type: isNaN(+value) ? "string" : "number",
    };

    return col;
  });

  const rows: TableRow[] = data.map((item) => {
    const entries = Object.entries(item);

    const row: TableRow = entries.reduce((acc, curr) => {
      const [key, value] = curr;

      return {
        ...acc,
        [key]: value,
      };
    }, {} as TableRow);

    return row;
  });

  return {
    data: {
      columns: cols,
      rows,
    },
    dataSchema: null,
    config: {
      dataFormat: "barRadar",
    },
  };
};

export const switchLineAreaHeatMapDataToTable = (
  data: LineAreaHeatMapScatterPlotData
): ExcellenceTableConfigAndData => {
  const valueX = data[0].data[0].x;
  const valueY = data[0].data[0].y;

  const cols: TableGridColumnSchema[] = [
    {
      id: "datum",
      label: "Datum",
      type: "string",
    },
    {
      id: "x",
      label: "X",
      type: isNaN(+valueX) ? "string" : "number",
    },
    {
      id: "y",
      label: "Y",
      type: isNaN(+valueY) ? "string" : "number",
    },
  ];

  const rows: TableRow[] = [];

  for (const item of data) {
    for (const datum of item.data) {
      rows.push({
        datum: item.id,
        x: datum.x,
        y: datum.y,
      });
    }
  }

  return {
    data: {
      columns: cols,
      rows,
    },
    dataSchema: null,
    config: {
      dataFormat: "lineAreaHeatMap",
      tableGridConfig: {
        grouping: ["datum"],
      },
    },
  };
};

// Advanced charts
export const switchBoxPlotDataToTable = (
  data: BoxPlotData
): ExcellenceTableConfigAndData => {
  const cols: TableGridColumnSchema[] = [
    {
      id: "group",
      label: "Group",
      type: "string",
    },
    {
      id: "subgroup",
      label: "Subgroup",
      type: "string",
    },
    {
      id: "mu",
      label: "mu",
      type: "number",
    },
    {
      id: "sd",
      label: "sd",
      type: "number",
    },
    {
      id: "n",
      label: "n",
      type: "number",
    },
    {
      id: "value",
      label: "value",
      type: "number",
    },
  ];

  const rows: TableRow[] = data.map((item) => ({
    group: item.group,
    subgroup: item.subgroup,
    value: item.value,
  }));

  return {
    data: {
      columns: cols,
      rows,
    },
    dataSchema: null,
    config: {
      dataFormat: "boxPlot",
      tableGridConfig: {
        grouping: ["group"],
      },
    },
  };
};

export const switchSunburstDataToTable = (
  data: SunburstData
): ExcellenceTableConfigAndData => {
  const cols: TableGridColumnSchema[] = [
    {
      id: "name",
      label: "Name",
      type: "string",
    },
    {
      id: "parent",
      label: "Parent",
      type: "string",
    },
    {
      id: "color",
      label: "Color",
      type: "string",
    },
    {
      id: "children",
      label: "Children",
      type: "string",
    },
    {
      id: "loc",
      label: "Location",
      type: "number",
    },
  ];

  const firstChildren = data.children.map((child) => child.name);
  const rows: TableRow[] = [
    {
      name: data.name,
      color: data.color,
      parent: null,
      children: firstChildren.join(", "),
    },
  ];

  rows.push(...sunburstChildrenToRows(data.children, data.name));

  return {
    data: {
      columns: cols,
      rows,
    },
    dataSchema: null,
    config: {
      dataFormat: "sunburst",
    },
  };
};

const sunburstChildrenToRows = (
  children: SunburstChildren,
  parent: string
): TableRow[] => {
  const rows: TableRow[] = [];

  for (const child of children) {
    // if the child has loc, push it to the rows
    if (child.loc) {
      rows.push({
        name: child.name,
        parent,
        color: child.color,
        children: null,
        loc: child.loc,
      });
    }
    // if the child has children, push its children
    // names to the rows array and recurse this function
    else if (child.children?.length) {
      // 1. push child names to the rows
      const namesSet = new Set<string>();
      for (const nestedChild of child.children) {
        namesSet.add(nestedChild.name);
      }

      const namesArray = Array.from(namesSet);
      const namesString = namesArray.join(", ");
      rows.push({
        name: child.name,
        parent,
        color: child.color,
        children: namesString,
        loc: null,
      });

      // 2. recurse this function and push it to the rows array
      const recurseRows = sunburstChildrenToRows(child.children, child.name);
      rows.push(...recurseRows);
    }
  }

  return rows;
};

// Chart data to table logic
export const handleSwitchFromChartDataToTable = (
  dataSchema: any,
  dateTimeConfig: ExcellenceDateTimeConfigurationData | null,
  data: ExcellenceTableData,
  newType: DynamicGridChartType,
  dataFormat: ExcellenceTableDataFormat,
  configuration: any
): ExcellenceSwitchIncomingData => {
  switch (newType) {
    // Excellence charts
    case "lineChart": {
      if (dataFormat === "lineAreaHeatMap") {
        return {
          lineAreaHeatMapChartData: switchTableDataToLineAreaHeatMap(data),
          dataSchema: dataSchema,
          dateTimeConfig: dateTimeConfig,
          configuration,
        };
      }
    }
    case "timeChart": {
      if (dataFormat === "lineAreaHeatMap") {
        return {
          lineAreaHeatMapChartData: switchTableDataToLineAreaHeatMap(data),
          dataSchema: dataSchema,
          dateTimeConfig: dateTimeConfig,
          configuration,
        };
      }
    }
    case "barChart": {
      if (dataFormat === "barRadar") {
        return {
          barColumnRadarData: switchTableDataToBarRadar(data),
          dataSchema: dataSchema,
          dateTimeConfig: dateTimeConfig,
          configuration,
        };
      }
    }
    case "columnChart": {
      if (dataFormat === "barRadar") {
        return {
          barColumnRadarData: switchTableDataToBarRadar(data),
          dataSchema: dataSchema,
          dateTimeConfig: dateTimeConfig,
          configuration,
        };
      }
    }
    case "pieChart": {
      if (dataFormat === "pie") {
        return {
          pieDonutChartData: switchTableDataToPie(data),
          dataSchema: dataSchema,
          dateTimeConfig: dateTimeConfig,
          configuration,
        };
      }
    }
    case "donutChart": {
      if (dataFormat === "pie") {
        return {
          pieDonutChartData: switchTableDataToPie(data),
          dataSchema: dataSchema,
          dateTimeConfig: dateTimeConfig,
          configuration,
        };
      }
    }
    case "radarChart": {
      if (dataFormat === "barRadar") {
        return {
          barColumnRadarData: switchTableDataToBarRadar(data),
          dataSchema: dataSchema,
          dateTimeConfig: dateTimeConfig,
          configuration,
        };
      }
    }
    case "areaChart": {
      if (dataFormat === "lineAreaHeatMap") {
        return {
          lineAreaHeatMapChartData: switchTableDataToLineAreaHeatMap(data),
          dataSchema: dataSchema,
          dateTimeConfig: dateTimeConfig,
          configuration,
        };
      }
    }
    // Advanced charts
    case "heatMap": {
      if (dataFormat === "lineAreaHeatMap") {
        return {
          lineAreaHeatMapChartData: switchTableDataToLineAreaHeatMap(data),
          dataSchema: dataSchema,
          dateTimeConfig: dateTimeConfig,
          configuration,
        };
      }
    }
    case "boxPlot": {
      if (dataFormat === "boxPlot") {
        return {
          boxPlotData: switchTableDataToBoxPlot(data),
          dataSchema: dataSchema,
          dateTimeConfig: dateTimeConfig,
          configuration,
        };
      }
    }
    case "sunburst": {
      if (dataFormat === "sunburst") {
        return {
          sunburstData: switchTableDataToSunburst(data),
          dataSchema: dataSchema,
          dateTimeConfig: dateTimeConfig,
          configuration,
        };
      }
    }
    default: {
      return {
        dataSchema: dataSchema,
        dateTimeConfig: dateTimeConfig,
        configuration,
      };
    }
  }
};

// Excellence
const switchTableDataToPie = (data: ExcellenceTableData): PieDonutChartData => {
  return data.rows as PieDonutChartData;
};

const switchTableDataToBarRadar = (data: ExcellenceTableData): BarColumnRadarData => {
  return data.rows as BarColumnRadarData;
};

const switchTableDataToLineAreaHeatMap = (
  data: ExcellenceTableData
): LineAreaHeatMapScatterPlotData => {
  // Use a Map to group data by 'datum' value
  const groupedDataMap = new Map<
    string,
    {
      id: string;
      data: {
        x: string | number;
        y: string | number;
      }[];
    }
  >();

  for (const item of data.rows) {
    const { datum, x, y } = item;
    if (groupedDataMap.has(datum)) {
      // If the 'datum' already exists, add the 'x' and 'y' values to the 'data' array
      const existingData = groupedDataMap.get(datum);
      if (existingData) {
        existingData.data.push({ x, y });
      }
    } else {
      // If the 'datum' doesn't exist, create a new entry in the Map
      groupedDataMap.set(datum, {
        id: datum,
        data: [{ x, y }],
      });
    }
  }

  // Convert the Map values to an array
  const transformedData = Array.from(groupedDataMap.values());

  return transformedData as LineAreaHeatMapScatterPlotData;
};

// Advanced
const switchTableDataToBoxPlot = (data: ExcellenceTableData): BoxPlotData => {
  return data.rows as BoxPlotData;
};

type SunburstChildrenTableRow = {
  name: string;
  color: string;
  parent: string;
  children: string[] | null;
  loc: number | null;
};

const switchTableDataToSunburst = (data: ExcellenceTableData): SunburstData => {
  const dataRows = data.rows as SunburstChildrenTableRow[];
  const rootChildren: SunburstChildren = tableRowsToSunburstChildren(
    dataRows,
    data.rows[0].name
  );

  return {
    name: data.rows[0].name,
    color: data.rows[0].color,
    children: rootChildren,
  };
};

const tableRowsToSunburstChildren = (
  rows: SunburstChildrenTableRow[],
  parent: string
): SunburstChildren => {
  const result: SunburstChildren = [];
  const parentItem = rows.find((row) => row.name === parent);

  if (parentItem) {
    // no nested children
    if (parentItem.loc) {
      result.push({
        name: parentItem.name,
        color: parentItem.color,
        loc: parentItem.loc,
      });
    }

    // has nested children
    else if (parentItem.children?.length) {
      const nestedChildrenRows = rows.filter((row) => row.parent === parentItem.name);

      const nestedChildren: SunburstChildren = nestedChildrenRows.map((row) => ({
        name: row.name,
        color: row.color,
        loc: row.loc || undefined,
        children: row.loc ? undefined : tableRowsToSunburstChildren(rows, row.name),
      }));

      result.push(...nestedChildren);
    }
  }

  return result;
};

export const getExcellenceTableID = (widgetID: string) => `excellence_table_${widgetID}`;

import {
  Box,
  IconButton,
  Stack,
  Tab,
  Tabs,
  Theme,
  Typography,
  useTheme,
} from "@mui/material";
import PublishedWithChangesIcon from "@mui/icons-material/PublishedWithChanges";
import Alert from "../../Components/MaterialUI/Alert";
import cssLayoutStyles from "../../Global/Styles/layout";
import cssSpacingStyles from "../../Global/Styles/spacing";
import { useEffect, useState } from "react";
import { FormStatuses, SelectOption } from "../../Global/Types/commonTypes";
import { useAuthedContext } from "../../context/AuthContext";
import Modal from "../../Components/MaterialUI/Modal";
import callApi from "../../Api/callApi";
import {
  getQueryElpromCollateralNotDistributed,
  getQueryElpromCompareData,
  getQueryElpromDates,
  getQueryElpromDetails,
  getQueryElpromFilesMetadata,
  getQueryElpromKso,
  getQueryElpromPrices,
  getQueryElpromPricesPo,
  getQueryElpromSupco,
} from "./API/apiElpromGetQueries";
import {
  ElpromFileMeta,
  ElpromTableData,
  ElpromTableDataCols,
} from "./API/apiElpromSnippets";
import cssFontStyles from "../../Global/Styles/font";
import ElpromFilesModal from "./Components/ElpromFilesModal";
import {
  calcTableFooterAggregations,
  TableAggregationFns,
  TableCellsConfig,
  TableDynamicCondition,
  TableGridColumnSchema,
} from "../../Components/SmallComponents/TableGrid/constructTableGrid";
import ResponsiveTableGrid from "../../Components/SmallComponents/TableGrid/ResponsiveTableGrid";
import { useLanguageContext } from "../../context/LanguageContext";
import LoadingBackdrop from "../../Components/MaterialUI/LoadingBackdrop";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useLocation, useNavigate } from "react-router-dom";
import { formatDateAndTime } from "../../Global/Utils/commonFunctions";
import Select from "../../Components/MaterialUI/FormFields/Select";
import Button from "../../Components/MaterialUI/Button";
import CompareArrowsIcon from "@mui/icons-material/CompareArrows";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";

const TABLE_HEIGHT = 1150;
const INITIAL_ROWS = 25;

type ModalType = "compare" | "files" | null;

type TableCols = {
  kso: TableGridColumnSchema[];
  supco: TableGridColumnSchema[];
  prices: TableGridColumnSchema[];
  details: TableGridColumnSchema[];
  notDist: TableGridColumnSchema[];
  pricesPo: TableGridColumnSchema[];
};

type TableRows = {
  kso: Record<string, any>[];
  supco: Record<string, any>[];
  prices: Record<string, any>[];
  details: Record<string, any>[];
  notDist: Record<string, any>[];
  pricesPo: Record<string, any>[];
};

type CompareData = null | {
  cols: TableGridColumnSchema[];
  rows: Record<string, any>[];
};

const ProjectHubDataPage: React.FC = () => {
  const theme = useTheme();
  const styles = { ...cssSpacingStyles(theme), ...cssLayoutStyles, ...cssFontStyles };
  const location = useLocation();

  const [pageStatus, setPageStatus] = useState<FormStatuses>(null);
  const [alertMessage, setAlertMessage] = useState<string | null>(null);
  const [openModal, setOpenModal] = useState<ModalType>(null);
  const [tabView, setTabView] = useState<number>(location.state?.tab || 0);
  const [filesMeta, setFilesMeta] = useState<ElpromFileMeta[]>([]);

  const [colsData, setColsData] = useState<TableCols | null>(null);
  const [rowsData, setRowsData] = useState<TableRows | null>(null);
  const [datesWithData, setDatesWithData] = useState<SelectOption[]>([]);
  const [selectedDate, setSelectedDate] = useState<string>(
    location.state?.selectedDate || ""
  );
  const [dateChangeLoading, setDateChangeLoading] = useState<boolean>(false);

  const [compareDate, setCompareDate] = useState<string>(
    location.state?.compareDate || ""
  );
  const [isComparing, setIsComparing] = useState<boolean>(!!location.state?.compareDate);
  const [compareData, setCompareData] = useState<CompareData>(null);

  console.log("colsData ", colsData);
  console.log("rowsData ", rowsData);

  const { setAuthedUser } = useAuthedContext();
  const { language, t } = useLanguageContext();
  const navigate = useNavigate();

  const cellBgStaticColor = theme.palette.mode === "dark" ? "#303030" : "whiteSmoke";

  const columnsStaticColors: Record<string, string> = {
    production_site: cellBgStaticColor,
    unit_price: cellBgStaticColor,
    total_value_in_bgn: cellBgStaticColor,
    total_nomenclatures: cellBgStaticColor,
    paint: theme.palette.primary.light,
    need_closure_source_with_overdue_zp: theme.palette.primary.light,
    remaining_stock_on_MS105: theme.palette.primary.light,
    remaining_stock_excluding_MS105: theme.palette.primary.light,
    total_deficit_consumption: theme.palette.secondary.light,
    minimum_deficit_required: theme.palette.secondary.light,
    total_consumption: theme.palette.primary.light,
    norm_per_detail: theme.palette.primary.light,
    base_unit_cooperation: theme.palette.primary.light,
    extended_material_name_cooperation: theme.palette.primary.light,
    cooperation_material: theme.palette.primary.light,
  };

  useEffect(() => {
    (async () => {
      try {
        const tab = location?.state?.tab;
        const selected = location?.state?.selectedDate;
        const compare = location?.state?.compareDate;
        if (compare && selected && tab) {
          await handleCompareData();
        }
      } catch (err) {
        console.log(err);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.state]);

  useEffect(() => {
    (async () => {
      try {
        setPageStatus("loading");
        await handleFetchFiles();
        await handleFetchTableData();
        setPageStatus(null);
        setAlertMessage(null);
      } catch (err) {
        console.log("err ", err);
        setPageStatus("error");
        setAlertMessage(t("Something went wrong"));
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language]);

  const handleOnDateChange = async (val: string) => {
    try {
      setDateChangeLoading(true);

      const details = await callApi<ElpromTableData>({
        query: getQueryElpromDetails(language as any, val),
        auth: { setAuthedUser },
      });
      const notDistributed = await callApi<ElpromTableData>({
        query: getQueryElpromCollateralNotDistributed(language as any, val),
        auth: { setAuthedUser },
      });

      setColsData((prev) =>
        prev
          ? {
              ...prev,
              details: handleColsData(
                details.columns,
                details.rows,
                columnsStaticColors,
                theme,
                [
                  "collateral_percentage",
                  "in_delivery_percentage",
                  "non_collateral_percentage",
                ],
                true
              ),
              notDist: handleColsData(
                notDistributed.columns,
                notDistributed.rows,
                columnsStaticColors,
                theme,
                [],
                false
              ),
            }
          : prev
      );
      setRowsData((prev) =>
        prev
          ? {
              ...prev,
              details: handleDetailsRows(details.rows),
              notDist: handleNotDistRows(notDistributed.rows),
            }
          : prev
      );

      setSelectedDate(val);
      setCompareDate("");
      setIsComparing(false);
    } catch (err) {
      console.log("handleOnDateChange err ", err);
    }
    setDateChangeLoading(false);
  };

  const handleCompareData = async () => {
    try {
      if (!selectedDate || !compareDate) {
        return;
      }

      setDateChangeLoading(true);
      const compareDates = await callApi<ElpromTableData>({
        query: getQueryElpromCompareData(language as any, selectedDate, compareDate),
        auth: { setAuthedUser },
      });
      const unequalValues = findUnequalValues(compareDates.rows);
      const diffCells: TableCellsConfig = unequalValues.map((val) => ({
        id: val,
        bgColor:
          theme.palette.mode === "light"
            ? theme.palette.warning.light
            : theme.palette.warning.dark,
      }));

      setCompareData({
        cols: handleColsData(
          compareDates.columns,
          compareDates.rows,
          columnsStaticColors,
          theme,
          [
            "collateral_percentage",
            "in_delivery_percentage",
            "non_collateral_percentage",
          ],
          true,
          undefined,
          diffCells
        ),
        rows: handleCompareRows(compareDates.rows),
      });

      setOpenModal(null);
      setIsComparing(true);
    } catch (err) {
      console.log("handleCompareData err: ", err);
    }
    setDateChangeLoading(false);
  };

  const handleStopCompare = async () => {
    try {
      if (!selectedDate) {
        return;
      }
      await handleOnDateChange(selectedDate);
      setCompareDate("");
      setIsComparing(false);
    } catch (err) {
      console.log("handleStopCompare err ", err);
    }
  };

  const handleFetchFiles = async () => {
    const meta = await callApi<ElpromFileMeta[]>({
      query: getQueryElpromFilesMetadata(),
      auth: { setAuthedUser },
    });
    setFilesMeta(meta);
  };

  const handleFetchTableData = async () => {
    const ksoLimit = 1000;
    const limit = 5000;
    const offset = 0;
    const language_code = language as any;

    const dates = await callApi<Date[]>({
      query: getQueryElpromDates(),
      auth: { setAuthedUser },
    });
    const sortedDates = dates.sort(
      (a, b) => new Date(b).getTime() - new Date(a).getTime()
    );
    const latestDate = new Date(sortedDates[0]).toISOString();

    const kso = await callApi<ElpromTableData>({
      query: getQueryElpromKso({ limit: ksoLimit, offset, language_code }),
      auth: { setAuthedUser },
    });
    const supco = await callApi<ElpromTableData>({
      query: getQueryElpromSupco({ limit, offset, language_code }),
      auth: { setAuthedUser },
    });
    const prices = await callApi<ElpromTableData>({
      query: getQueryElpromPrices({ limit, offset, language_code }),
      auth: { setAuthedUser },
    });
    const details = await callApi<ElpromTableData>({
      query: getQueryElpromDetails(language_code, latestDate),
      auth: { setAuthedUser },
    });
    const notDistributed = await callApi<ElpromTableData>({
      query: getQueryElpromCollateralNotDistributed(language_code, latestDate),
      auth: { setAuthedUser },
    });
    const pricesPo = await callApi<ElpromTableData>({
      query: getQueryElpromPricesPo({ limit, offset, language_code }),
      auth: { setAuthedUser },
    });

    setColsData({
      kso: handleColsData(kso.columns, kso.rows, columnsStaticColors, theme, []),
      supco: handleColsData(supco.columns, supco.rows, columnsStaticColors, theme, []),
      prices: handleColsData(prices.columns, prices.rows, columnsStaticColors, theme, []),
      pricesPo: handleColsData(
        pricesPo.columns,
        pricesPo.rows,
        columnsStaticColors,
        theme,
        []
      ),
      details: handleColsData(
        details.columns,
        details.rows,
        columnsStaticColors,
        theme,
        ["collateral_percentage", "in_delivery_percentage", "non_collateral_percentage"],
        true
      ),
      notDist: handleColsData(
        notDistributed.columns,
        notDistributed.rows,
        columnsStaticColors,
        theme,
        [],
        false
      ),
    });
    setRowsData({
      kso: kso.rows,
      supco: supco.rows,
      prices: prices.rows,
      pricesPo: pricesPo.rows,
      details: handleDetailsRows(details.rows),
      notDist: handleNotDistRows(notDistributed.rows),
    });

    setSelectedDate(latestDate);
    setDatesWithData(
      sortedDates.map((item) => ({
        value: new Date(item).toISOString(),
        description: formatDateAndTime(new Date(item).toISOString(), "date"),
      }))
    );
  };

  const handleDetailsRows = (data: Record<string, any>[]) => {
    return data.map((item) => {
      return {
        ...item,
        actions: (
          <IconButton
            onClick={() => {
              navigate(`/GIANT-Planning/project-hub-data/${item.client_order}`, {});
            }}
            size="small"
            sx={{ marginTop: -1, marginBottom: -1 }}
          >
            <VisibilityIcon />
          </IconButton>
        ),
      };
    });
  };

  const handleCompareRows = (data: Record<string, any>[]) => {
    return data.map((item) => {
      return {
        ...item,
        actions: (
          <IconButton
            onClick={() => {
              navigate(
                `/GIANT-Planning/project-hub-data/compare/${item.client_order_1}`,
                {
                  state: { language, selectedDate, compareDate },
                }
              );
            }}
            size="small"
            sx={{ marginTop: -1, marginBottom: -1 }}
          >
            <VisibilityIcon />
          </IconButton>
        ),
      };
    });
  };

  const handleNotDistRows = (data: Record<string, any>[]) => {
    return data.map((item) => {
      return {
        ...item,
        actions: (
          <IconButton
            onClick={() => {
              navigate(`/GIANT-Planning/project-hub-data/kso/${item.client_order}`, {});
            }}
            size="small"
            sx={{ marginTop: -1, marginBottom: -1 }}
          >
            <VisibilityIcon />
          </IconButton>
        ),
      };
    });
  };

  return (
    <Box component="div">
      <Stack
        css={[styles.width100, styles.labelBreak]}
        spacing={2}
        alignItems="center"
        justifyContent="space-between"
        direction="row"
      >
        <Box component="div" />
        <Typography variant="h2" textAlign="center">
          {t("Collateral")}
        </Typography>

        <Stack spacing={1} alignItems="center">
          <IconButton
            aria-label="sync currencies"
            onClick={() => setOpenModal("files")}
            disabled={pageStatus === "loading"}
          >
            <PublishedWithChangesIcon color="primary" />
          </IconButton>
        </Stack>
      </Stack>

      <Alert
        css={[styles.widthLimit20, styles.labelBreak]}
        message={alertMessage || ""}
        showAlert={!!alertMessage}
        severity={pageStatus}
      />

      <Stack alignItems={"center"} justifyContent="center">
        <Box sx={{ width: { xs: "100%", md: "auto" } }} component="div">
          <Tabs
            css={styles.contentBreak}
            sx={{ overflowX: "auto" }}
            value={tabView}
            onChange={(_: any, val: React.SetStateAction<number>) => setTabView(val)}
            aria-label="configure tabs"
            variant="scrollable"
            scrollButtons="auto"
          >
            <Tab label={t("Collateral Summary")} />
            <Tab label="Supco" />
            <Tab label="Kso" />
            <Tab label={t("Prices")} />
            <Tab label={t("Prices PO")} />
            <Tab label={t("Collateral Not Distributed")} />
            <Tab label={t("Collateral Compare")} />
          </Tabs>
        </Box>
      </Stack>

      {pageStatus === "loading" ? (
        <Box css={styles.width100} sx={{ height: "10rem" }} component="div">
          <LoadingBackdrop loading={pageStatus === "loading"} />
        </Box>
      ) : rowsData && colsData ? (
        <>
          {tabView === 0 ? (
            <>
              <Stack
                css={styles.labelBreak}
                direction="row"
                spacing={3}
                alignItems="center"
                justifyContent="center"
              >
                <Select
                  css={[styles.width100, styles.widthLimit10]}
                  label={t("Date")}
                  selectOptions={datesWithData}
                  value={selectedDate}
                  onChange={(e) => handleOnDateChange(e.target.value)}
                  disabled={dateChangeLoading || !datesWithData?.length}
                />
              </Stack>

              <Box component="div">
                <ResponsiveTableGrid
                  rows={rowsData.details}
                  colSchema={colsData.details}
                  responsive="desktop"
                  configuration={{
                    density: "compact",
                    initialRowsPerPage: INITIAL_ROWS,
                    columnVisibility: {
                      id: false,
                      project_lines: false,
                      incomming_control_value_in_bgn: false,
                      available_on_stock_value_in_bgn: false,
                      placed_po_nomenclatures: false,
                      placed_po_value_in_bgn: false,
                      expedition: false,
                      project_lines_1: false,
                      project_lines_2: false,
                      client_order_1: false,
                    },
                    enableStickyHeader: true,
                    maxTableHeight: TABLE_HEIGHT,
                    grouping: ["priority"],
                    columnPinning: {
                      right: ["actions"],
                      left: [],
                    },
                  }}
                  tableID="ProjectHubDataPage_Collateral_Summary_table"
                  backdropLoading={dateChangeLoading}
                />
              </Box>
            </>
          ) : null}
          {tabView === 1 ? (
            <ResponsiveTableGrid
              rows={rowsData.supco}
              colSchema={colsData.supco}
              responsive="desktop"
              configuration={{
                density: "compact",
                initialRowsPerPage: INITIAL_ROWS,
                columnVisibility: { id: false },
                enableStickyHeader: true,
                maxTableHeight: TABLE_HEIGHT,
              }}
              tableID="ProjectHubDataPage_Supco_table"
            />
          ) : null}
          {tabView === 2 ? (
            <ResponsiveTableGrid
              rows={rowsData.kso}
              colSchema={colsData.kso}
              responsive="desktop"
              configuration={{
                density: "compact",
                initialRowsPerPage: INITIAL_ROWS,
                columnVisibility: { id: false },
                enableStickyHeader: true,
                maxTableHeight: TABLE_HEIGHT,
              }}
              tableID="ProjectHubDataPage_Kso_table"
            />
          ) : null}
          {tabView === 3 ? (
            <ResponsiveTableGrid
              rows={rowsData.prices}
              colSchema={colsData.prices}
              responsive="desktop"
              configuration={{
                density: "compact",
                initialRowsPerPage: INITIAL_ROWS,
                columnVisibility: { id: false },
                enableStickyHeader: true,
                maxTableHeight: TABLE_HEIGHT,
              }}
              tableID="ProjectHubDataPage_Prices_table"
            />
          ) : null}

          {tabView === 4 ? (
            <ResponsiveTableGrid
              rows={rowsData.pricesPo}
              colSchema={colsData.pricesPo}
              responsive="desktop"
              configuration={{
                density: "compact",
                initialRowsPerPage: INITIAL_ROWS,
                columnVisibility: { id: false },
                enableStickyHeader: true,
                maxTableHeight: TABLE_HEIGHT,
              }}
              tableID="ProjectHubDataPage_Prices_po_table"
            />
          ) : null}
          {tabView === 5 ? (
            <>
              <Stack
                css={styles.labelBreak}
                direction="row"
                spacing={3}
                alignItems="center"
                justifyContent="center"
              >
                <Select
                  css={[styles.width100, styles.widthLimit10]}
                  label={t("Date")}
                  selectOptions={datesWithData}
                  value={selectedDate}
                  onChange={(e) => handleOnDateChange(e.target.value)}
                  disabled={dateChangeLoading || !datesWithData?.length}
                />
              </Stack>

              <Box component="div">
                <ResponsiveTableGrid
                  rows={rowsData.notDist}
                  colSchema={colsData.notDist}
                  responsive="desktop"
                  configuration={{
                    density: "compact",
                    initialRowsPerPage: INITIAL_ROWS,
                    columnVisibility: { id: false },
                    enableStickyHeader: true,
                    maxTableHeight: TABLE_HEIGHT,
                    grouping: ["category"],
                  }}
                  tableID="ProjectHubDataPage_Collateral_not_Distributed_table"
                  backdropLoading={dateChangeLoading}
                />
              </Box>
            </>
          ) : null}
          {tabView === 6 ? (
            <>
              <Stack
                css={styles.labelBreak}
                direction="row"
                spacing={3}
                alignItems="center"
                justifyContent="center"
              >
                <Select
                  css={[styles.width100, styles.widthLimit10]}
                  label={t("Date")}
                  selectOptions={datesWithData.filter(
                    (item) => item.value !== compareDate
                  )}
                  value={selectedDate}
                  onChange={(e) => handleOnDateChange(e.target.value)}
                  disabled={dateChangeLoading || !datesWithData?.length}
                />
                {compareDate && isComparing ? (
                  <Stack direction="row" alignItems="center" spacing={3}>
                    <CompareArrowsIcon />
                    <Select
                      css={[styles.width100, styles.widthLimit10]}
                      label={t("Compare Against")}
                      selectOptions={datesWithData.filter(
                        (item) => item.value !== selectedDate
                      )}
                      value={compareDate}
                      onChange={(e) => setCompareDate(e.target.value)}
                      disabled={dateChangeLoading || !datesWithData?.length}
                    />
                    <IconButton
                      onClick={handleStopCompare}
                      disabled={dateChangeLoading || !datesWithData?.length}
                    >
                      <DeleteOutlineIcon color="error" />
                    </IconButton>
                  </Stack>
                ) : (
                  <Button
                    variant="text"
                    color="secondary"
                    onClick={() => setOpenModal("compare")}
                  >
                    Compare
                  </Button>
                )}
              </Stack>

              {isComparing && compareData ? (
                <Box component="div">
                  <ResponsiveTableGrid
                    rows={compareData.rows}
                    colSchema={compareData.cols}
                    responsive="desktop"
                    configuration={{
                      density: "compact",
                      initialRowsPerPage: INITIAL_ROWS,
                      columnVisibility: {
                        id: false,
                        project_lines: false,
                        incomming_control_value_in_bgn: false,
                        available_on_stock_value_in_bgn: false,
                        placed_po_nomenclatures: false,
                        placed_po_value_in_bgn: false,
                        expedition: false,
                        project_lines_1: false,
                        project_lines_2: false,
                        client_order_1: false,
                      },
                      columnPinning: {
                        right: ["actions"],
                        left: [],
                      },
                      enableStickyHeader: true,
                      maxTableHeight: TABLE_HEIGHT,
                    }}
                    tableID="ProjectHubDataPage_Collateral_Compare_table"
                    backdropLoading={dateChangeLoading}
                  />
                </Box>
              ) : null}
            </>
          ) : null}
        </>
      ) : null}

      <Modal
        open={!!openModal}
        onClose={() => setOpenModal(null)}
        fullWidth
        maxWidth={openModal === "files" ? "md" : "sm"}
        label={openModal === "files" ? t("Manage Files") : t("Compare Data")}
      >
        {openModal === "files" ? (
          <ElpromFilesModal
            filesMeta={filesMeta}
            handleFetchTableData={handleFetchTableData}
            handleFetchFiles={handleFetchFiles}
          />
        ) : null}

        {openModal === "compare" ? (
          <Stack spacing={2}>
            <Typography variant="body1">
              {t("Select a date and compare it against")}{" "}
              {selectedDate ? formatDateAndTime(selectedDate, "date") : ""}
            </Typography>

            <Select
              css={[styles.width100, styles.widthLimit15]}
              label={t("Compare Against")}
              selectOptions={datesWithData.filter((item) => item.value !== selectedDate)}
              value={compareDate}
              onChange={(e) => setCompareDate(e.target.value)}
              disabled={dateChangeLoading || !datesWithData?.length}
            />

            <Stack alignItems={"center"} justifyContent={"center"}>
              <Button onClick={handleCompareData} disabled={!compareDate}>
                {t("Confirm and Compare")}
              </Button>
            </Stack>
          </Stack>
        ) : null}
      </Modal>
    </Box>
  );
};

export default ProjectHubDataPage;

const aggregations = [TableAggregationFns.sum];
const aggAndAvg = [TableAggregationFns.mean];

const handleColsData = (
  cols: ElpromTableDataCols[],
  rows: Record<string, any>[],
  columnsStaticColors: Record<string, string>,
  theme: Theme,
  colsWithAverage: string[],
  withActions?: boolean,
  width?: number,
  cellsConfig?: TableCellsConfig
): TableGridColumnSchema[] => {
  const action = { id: "actions", label: "Actions", type: "button" };
  const result: TableGridColumnSchema[] = cols.map((item) => {
    const isNumb = item.type === "number";
    const hasAvg = colsWithAverage.includes(item.id);

    return {
      id: item.id,
      label: item.name,
      type: item.type,
      colBgColor: columnsStaticColors[item.id],
      formatNumb: true,
      aggregationFn: isNumb ? (hasAvg ? aggAndAvg : aggregations) : undefined,
      footerAggregations: isNumb
        ? calcTableFooterAggregations(
            hasAvg ? aggAndAvg : aggregations,
            rows.map((row) => row[item.id]),
            item.decimal_points
          )
        : undefined,
      dynamicBgColor: dynamicBgColors(item, theme),
      ...(width && { width: width }),
      alignCol: item.alignment,
      formatNumbDecimals: item.decimal_points,
      symbol: {
        align: item.symbol_alignment === "before" ? "left" : "right",
        value: item.symbol || "",
      },
      cellsConfig,
    };
  });

  if (withActions) {
    return [...result, action] as TableGridColumnSchema[];
  } else {
    return result as TableGridColumnSchema[];
  }
};

const dynamicBgColors = (cols: ElpromTableDataCols, theme: Theme) => {
  const operation: TableDynamicCondition = {
    operation: "always",
    cellValue: 0,
  };

  if (cols.id === "collateral_percentage") {
    return {
      ...operation,
      bgColor: theme.palette.success.main,
      partialFillBasedOnVal: true,
    };
  }
  if (cols.id === "in_delivery_percentage") {
    return {
      ...operation,

      bgColor: theme.palette.warning.main,
      partialFillBasedOnVal: true,
    };
  }
  if (cols.id === "non_collateral_percentage") {
    return {
      ...operation,

      bgColor: theme.palette.error.main,
      partialFillBasedOnVal: true,
    };
  }

  return undefined;
};

const findUnequalValues = (rows: Record<string, any>[]): string[] => {
  const unequalIds: string[] = [];

  rows.forEach((item, index) => {
    if (item.total_nomenclatures_1 !== item.total_nomenclatures_2) {
      unequalIds.push(`${index}_total_nomenclatures_1`);
    }
    if (item.total_value_in_bgn_1 !== item.total_value_in_bgn_2) {
      unequalIds.push(`${index}_total_value_in_bgn_1`);
    }
    if (item.priority_1 !== item.priority_2) {
      unequalIds.push(`${index}_priority_1`);
    }
    if (item.client_order_1 !== item.client_order_2) {
      unequalIds.push(`${index}_client_order_1`);
    }
    if (item.project_lines_1 !== item.project_lines_2) {
      unequalIds.push(`${index}_project_lines_1`);
    }
    if (item.collateral_percentage_1 !== item.collateral_percentage_2) {
      unequalIds.push(`${index}_collateral_percentage_1`);
    }
    if (item.in_delivery_percentage_1 !== item.in_delivery_percentage_2) {
      unequalIds.push(`${index}_in_delivery_percentage_1`);
    }
    if (item.non_collateral_percentage_1 !== item.non_collateral_percentage_2) {
      unequalIds.push(`${index}_non_collateral_percentage_1`);
    }
    if (
      item.incomming_control_nomenclatures_1 !== item.incomming_control_nomenclatures_2
    ) {
      unequalIds.push(`${index}_incomming_control_nomenclatures_1`);
    }
    if (item.total_nomenclatures_1 !== item.total_nomenclatures_2) {
      unequalIds.push(`${index}_total_nomenclatures_1`);
    }
    if (item.incomming_control_value_in_bgn_1 !== item.incomming_control_value_in_bgn_2) {
      unequalIds.push(`${index}_incomming_control_value_in_bgn_1`);
    }
    if (
      item.available_on_stock_nomenclatures_1 !== item.available_on_stock_nomenclatures_2
    ) {
      unequalIds.push(`${index}_available_on_stock_nomenclatures_1`);
    }
    if (
      item.available_on_stock_value_in_bgn_1 !== item.available_on_stock_value_in_bgn_2
    ) {
      unequalIds.push(`${index}_available_on_stock_value_in_bgn_1`);
    }
    if (item.placed_po_nomenclatures_1 !== item.placed_po_nomenclatures_2) {
      unequalIds.push(`${index}_placed_po_nomenclatures_1`);
    }
    if (item.placed_po_value_in_bgn_1 !== item.placed_po_value_in_bgn_2) {
      unequalIds.push(`${index}_placed_po_value_in_bgn_1`);
    }
    if (item.not_purchased_nomenclatures_1 !== item.not_purchased_nomenclatures_2) {
      unequalIds.push(`${index}_not_purchased_nomenclatures_1`);
    }
    if (item.not_purchased_value_in_bgn_1 !== item.not_purchased_value_in_bgn_2) {
      unequalIds.push(`${index}_not_purchased_value_in_bgn_1`);
    }
    if (item.client_desired_ship_date_1 !== item.client_desired_ship_date_2) {
      unequalIds.push(`${index}_client_desired_ship_date_1`);
    }
    if (item.expedition_1 !== item.expedition_2) {
      unequalIds.push(`${index}_expedition_1`);
    }
    if (item.latest_delivery_date_placed_po_1 !== item.latest_delivery_date_placed_po_2) {
      unequalIds.push(`${index}_latest_delivery_date_placed_po_1`);
    }
    if (
      item.latest_delivery_date_not_purchased_1 !==
      item.latest_delivery_date_not_purchased_2
    ) {
      unequalIds.push(`${index}_latest_delivery_date_not_purchased_1`);
    }
    if (item.production_ready_date_1 !== item.production_ready_date_2) {
      unequalIds.push(`${index}_production_ready_date_1`);
    }
  });

  return unequalIds;
};

import { useEffect, useState } from "react";
import { Box, IconButton, Stack, Tooltip, Typography, useTheme } from "@mui/material";
import ResponsiveTableGrid from "../../Components/SmallComponents/TableGrid/ResponsiveTableGrid";
import { TableGridColumnSchema } from "../../Components/SmallComponents/TableGrid/constructTableGrid";
import callApi from "../../Api/callApi";
import { useAuthedContext } from "../../context/AuthContext";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import { useOutletContext } from "react-router-dom";
import { AppRouterProps } from "../../Layout/layoutVariables";
import Modal from "../../Components/MaterialUI/Modal";
import MaintainLocationsForm from "../../Components/PageComponents/Warehouses/MaintainLocationsForm";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import PlaceIcon from "@mui/icons-material/Place";
import { ModalLayoutData } from "../../Components/SmallComponents/TableGrid/CellActionButton";
import Button from "../../Components/MaterialUI/Button";
import cssLayoutStyles from "../../Global/Styles/layout";
import cssSpacingStyles from "../../Global/Styles/spacing";
import LocationNetworkChart from "../../Components/PageComponents/Warehouses/LocationNetworkChart";
import { GetQueryLocationsSnippet } from "../../Api/Locations/apiLocationSnippets";
import { getQueryLocations } from "../../Api/Locations/apiLocationsGetQueries";
import { postQueryDeleteLocation } from "../../Api/Locations/apiLocationsPostQueries";
import { useLanguageContext } from "../../context/LanguageContext";
import { useTranslateFields } from "../../Global/Hooks/useTranslations";
import CustomIconsTooltip from "../../Components/SmallComponents/Tooltip/CustomIconsTooltip";
import PageTitle from "../../Components/SmallComponents/PageTitle/PageTitle";

interface Location {
  location_code: string;
  location_name: React.ReactNode;
  location_type: string;
  updated_at: string;
  created_at: string;
  actions: React.ReactNode;
}

const colSchema: TableGridColumnSchema[] = [
  { id: "location_code", label: "Location Code", type: "string" },
  { id: "location_name", label: "Location", type: "button" },
  { id: "location_type", label: "Type", type: "string" },
  { id: "created_at", label: "Created At", type: "date" },
  { id: "updated_at", label: "Updated At", type: "date" },
  { id: "actions", label: "Actions", type: "button" },
];

const MaintainLocationsPage = () => {
  const { t } = useLanguageContext();
  const theme = useTheme();
  const styles = { ...cssLayoutStyles, ...cssSpacingStyles(theme) };
  const [locationsData, setLocationsData] = useState<GetQueryLocationsSnippet>();
  const [openInfo, setOpenInfo] = useState<boolean>(false);
  const [modalData, setModalData] = useState<ModalLayoutData | null>(null);
  const { setAuthedUser } = useAuthedContext();
  const [locationView, setLocationView] = useState<boolean>(false);
  const [selectedLocationId, setSelectedLocationId] = useState<string | null>(null);
  const [unsavedChanges, setUnsavedChanges] = useState<boolean>(false);
  const { smMediaQuery, setExtraTopNavMenu, setExtraRightNavMenu } =
    useOutletContext<AppRouterProps>();

  const rows = handleFilterData(
    setOpenInfo,
    setModalData,
    locationsData,
    setSelectedLocationId,
    setLocationView
  );
  console.log("rows ", rows);

  useEffect(() => {
    if (smMediaQuery) {
      setExtraTopNavMenu(null);
      setExtraRightNavMenu(null);
    } else {
      setExtraTopNavMenu(<PageTitle title={t("Locations")} />);
      setExtraRightNavMenu(
        <CreateNewLocationMenu
          handleOpenModal={() => {
            setOpenInfo(true);
            setModalData({ type: "locationsForm", data: "" });
          }}
        />
      );
    }

    return () => {
      setExtraTopNavMenu(null);
      setExtraRightNavMenu(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [smMediaQuery]);

  useEffect(() => {
    fetchLocations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchLocations = async () => {
    try {
      const data = await callApi<GetQueryLocationsSnippet>({
        query: getQueryLocations,
        auth: { setAuthedUser },
      });

      setLocationsData(data);
    } catch (error) {
      console.log("There was an error fetcing locations ", error);
    }
  };

  const handleDeleteTask = async (locationId: string) => {
    try {
      const deletedLocation = await callApi({
        query: postQueryDeleteLocation(locationId),
        auth: { setAuthedUser },
      });

      if (deletedLocation) {
        setLocationsData((prev) => {
          if (!prev) return prev;

          return prev.filter((location) => location.id !== locationId);
        });
      }
      setOpenInfo(false);
    } catch (err) {
      console.log("Location delete err: ", err.message);
    }
  };

  const handleSetUnsavedChanges = (unsavedChanges: boolean) => {
    if (unsavedChanges) {
      setUnsavedChanges(true);
    }
  };

  return (
    <>
      <Stack
        css={[styles.width100, styles.contentBreak]}
        spacing={2}
        alignItems="center"
        justifyContent="space-between"
        direction="row"
      >
        <Box component="div" />
        {smMediaQuery ? (
          <PageTitle css={styles.labelBreak} title={t("Locations")} />
        ) : null}
        <Button
          onClick={() => {
            setLocationView((prev) => !prev);
            setSelectedLocationId(null);
          }}
          disabled={!locationsData}
        >
          {locationView ? t("Table") : t("Location")} {t("View")}
        </Button>
      </Stack>

      {locationView && locationsData ? (
        <LocationNetworkChart data={locationsData} selectedNodeId={selectedLocationId} />
      ) : null}

      {!locationView ? (
        <ResponsiveTableGrid
          rows={rows}
          colSchema={useTranslateFields(colSchema, ["label"])}
          editMode={false}
          responsive="responsive"
          configuration={{
            density: "compact",
          }}
          tableID="MaintainLocationsPage_table"
        />
      ) : null}

      {modalData ? (
        <Modal
          open={openInfo}
          fullWidth
          label={
            modalData && modalData.type === "locationsForm" && !modalData.data
              ? t("Create Location")
              : modalData && modalData.type === "locationsForm" && modalData.data
              ? t("Update Location")
              : modalData && modalData.type === "deleteForm"
              ? t("Delete Location")
              : ""
          }
          onClose={() => setOpenInfo(false)}
          unsavedChanges={unsavedChanges}
          setUnsavedChanges={setUnsavedChanges}
        >
          {modalData && modalData.type === "locationsForm" ? (
            <MaintainLocationsForm
              locationData={modalData.data}
              setLocationsData={setLocationsData}
              fetchLocations={fetchLocations}
              setOpenLocationModal={setOpenInfo}
              handleSetUnsavedChanges={handleSetUnsavedChanges}
              setUnsavedChanges={setUnsavedChanges}
            />
          ) : null}

          {modalData && modalData.type === "deleteForm" ? (
            <Stack spacing={2}>
              <Typography variant="body1">
                {t("Are you sure you want to delete this location?")}
              </Typography>

              <Stack direction="row" justifyContent="center" alignItems="center" gap={1}>
                <Button onClick={() => setOpenInfo(false)} fullWidth>
                  {t("Cancel")}
                </Button>
                <Button
                  onClick={() => handleDeleteTask(modalData.data)}
                  color="error"
                  fullWidth
                >
                  {t("Confirm Deletion")}
                </Button>
              </Stack>
            </Stack>
          ) : null}
        </Modal>
      ) : null}
    </>
  );
};

export default MaintainLocationsPage;

interface CreateNewLocationMenuProps {
  handleOpenModal: () => void;
}

const CreateNewLocationMenu: React.FC<CreateNewLocationMenuProps> = ({
  handleOpenModal,
}) => {
  const { t } = useLanguageContext();
  return (
    <Stack alignItems="center">
      <CustomIconsTooltip tooltipText={t("Add")}>
        <IconButton aria-label="create new project" onClick={handleOpenModal}>
          <AddOutlinedIcon />
        </IconButton>
      </CustomIconsTooltip>
    </Stack>
  );
};

const handleFilterData = (
  setOpenInfo: React.Dispatch<React.SetStateAction<boolean>>,
  setModalData: React.Dispatch<React.SetStateAction<ModalLayoutData | null>>,
  locationsData: GetQueryLocationsSnippet | undefined,
  setSelectedLocationId: React.Dispatch<React.SetStateAction<string | null>>,
  setLocationView: React.Dispatch<React.SetStateAction<boolean>>
): Location[] => {
  if (!locationsData) return [];

  const filteredLocations: Location[] = [];
  if (locationsData) {
    for (let i = 0; i < locationsData.length; i++) {
      const location = locationsData[i];
      if (!location.is_deleted) {
        const locationParts = location.location.split("/");
        let displayLocation = locationParts[locationParts.length - 1];

        if (locationParts.length > 2) {
          displayLocation = `.../${locationParts[locationParts.length - 2]}/${
            locationParts[locationParts.length - 1]
          }`;
        } else {
          displayLocation = location.location;
        }

        filteredLocations.push({
          location_code: location.location_code,
          location_type: location.location_type,
          updated_at: location.updated_at,
          created_at: location.created_at,
          location_name: (
            <Stack>
              <Tooltip title={location.location}>
                <Typography component="p" variant="body1">
                  {displayLocation}
                </Typography>
              </Tooltip>
            </Stack>
          ),
          actions: (
            <Stack direction="row" justifyContent="flex-end" alignItems="center">
              <IconButton
                onClick={() => {
                  setSelectedLocationId(location.id);
                  setLocationView((prev) => !prev);
                }}
              >
                <PlaceIcon />
              </IconButton>
              <IconButton
                onClick={() => {
                  setOpenInfo(true);
                  setModalData({ type: "locationsForm", data: location });
                }}
              >
                <EditIcon />
              </IconButton>
              <IconButton
                onClick={() => {
                  setOpenInfo(true);
                  setModalData({ type: "deleteForm", data: location.id });
                }}
              >
                <DeleteIcon />
              </IconButton>
            </Stack>
          ),
        });
      }
    }
  }
  return filteredLocations;
};

import { SerializedStyles, css } from "@emotion/react";
import { Box, Divider, Drawer } from "@mui/material";
import {
  LEFT_NAV_WIDTH,
  LeftNavSingleItem,
  TOP_NAV_SPACING_WITH_SITE_CONTENT,
} from "../layoutVariables";
import LeftNavListMenu from "./LeftNavListMenu";
import {
  useTranslatedElpromNav,
  useTranslatedNav,
} from "../../Global/Hooks/useTranslatedNav";
import { NAV_GIANT_TOOLBOX_MANAGER } from "./leftNavData2";
import { useEffect, useMemo, useState } from "react";
import callApi from "../../Api/callApi";
import { useAuthedContext } from "../../context/AuthContext";
import { getQueryUserPreference } from "../../Api/UserPreferences/apiUserPreferencesGetQueries";
import { GetQueryMyFavouritesLeftNavItems } from "../../Api/UserPreferences/apiUserPreferencesSnippets";
import { PostQueryUserPreferenceInput } from "../../Api/UserPreferences/apiUserPreferencesInputs";
import { postQueryUserPreference } from "../../Api/UserPreferences/apiUserPreferencesPostQueries";
import { findFavouriteItems } from "./leftNavigationUtils";
import { FormStatuses } from "../../Global/Types/commonTypes";

const cssStyles = (openLeftNav: boolean) => ({
  drawer: css({
    width: openLeftNav ? LEFT_NAV_WIDTH : 0,
    flexShrink: 0,
    "& .MuiDrawer-paper": {
      width: LEFT_NAV_WIDTH,
      boxSizing: "border-box",
      border: "none",
    },
  }),
  leftNavContent: css({
    marginTop: TOP_NAV_SPACING_WITH_SITE_CONTENT,
    overflow: "auto",
    height: "100%",
  }),
});

const IS_ELRPOM_ENV = process.env.REACT_APP_SUBDOMAIN === "elprom";

interface LeftNavigationProps {
  css?: SerializedStyles[] | SerializedStyles;
  className?: string;
  openLeftNav: boolean;
  setOpenLeftNav: React.Dispatch<React.SetStateAction<boolean>>;
  mobileLeftNav: boolean;
}

const LeftNavigation: React.FC<LeftNavigationProps> = ({
  className,
  openLeftNav,
  setOpenLeftNav,
  mobileLeftNav,
}) => {
  const { setAuthedUser } = useAuthedContext();
  const styles = { ...cssStyles(openLeftNav) };
  const {
    NAV_GIANT_HOME,
    NAV_GIANT_ANALYTICS,
    NAV_GIANT_PLANNING,
    NAV_GIANT_OPERATIONS,
  } = useTranslatedNav();
  const { NAV_GIANT_ELPROM } = useTranslatedElpromNav();
  const [favourites, setFavourites] = useState<string[]>([]);
  const [isEditFavouritesMode, setIsEditFavouritesMode] = useState<boolean>(false);
  const [formStatus, setFormStatus] = useState<FormStatuses>(null);
  const [alertMessage, setAlertMessage] = useState<string | null>(null);

  const favouriteNavList = useMemo(() => {
    return findFavouriteItems(favourites, [
      ...NAV_GIANT_HOME.list,
      ...NAV_GIANT_ANALYTICS.list,
      ...NAV_GIANT_PLANNING.list,
      ...NAV_GIANT_OPERATIONS.list,
      ...NAV_GIANT_TOOLBOX_MANAGER.list,
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [favourites]);

  useEffect(() => {
    fetchFavourites();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchFavourites = async () => {
    try {
      setFormStatus("loading");

      const allPreferences = await callApi<GetQueryMyFavouritesLeftNavItems>({
        query: getQueryUserPreference("MyFavouritesLeftNavItems"),
        auth: { setAuthedUser },
      });

      setFavourites(allPreferences?.global || []);
      setFormStatus(null);
    } catch (error) {
      setFormStatus("error");
      setAlertMessage("Something went wrong");
      console.error("Error fetching favourites menu items");
    }
  };

  const toggleFavourite = async (item: LeftNavSingleItem) => {
    try {
      setFormStatus("loading");

      const updatedFavourites = favourites.includes(item.text)
        ? favourites.filter((url) => url !== item.text)
        : [...favourites, item.text];

      const input: PostQueryUserPreferenceInput = {
        preference_key: "MyFavouritesLeftNavItems",
        preferences: { global: updatedFavourites },
      };

      await callApi({
        query: postQueryUserPreference(input),
        auth: { setAuthedUser },
      });

      fetchFavourites();
      setFormStatus(null);
    } catch (error) {
      setFormStatus("error");
      setAlertMessage("Something went wrong");
      console.error(`Error toggling favourites menu item: ${item.text}`);
    }
  };

  const handleEditClick = () => {
    setIsEditFavouritesMode((prev) => !prev);
  };

  const handleClose = () => {
    setOpenLeftNav(false);
    window.dispatchEvent(new Event("resize"));
  };

  return (
    <Drawer
      className={className}
      css={styles.drawer}
      anchor="left"
      open={openLeftNav}
      onClose={handleClose}
      variant={mobileLeftNav ? "temporary" : "persistent"}
    >
      <Box component="div" css={styles.leftNavContent}>
        <LeftNavListMenu
          navList={favouriteNavList}
          listTitle="My Favourites"
          isEditFavouritesMode={isEditFavouritesMode}
          onEditClick={handleEditClick}
          toggleFavourite={toggleFavourite}
          favourites={favourites}
          formStatus={formStatus}
          alertMessage={alertMessage}
        />
        <Divider />

        {IS_ELRPOM_ENV ? (
          <LeftNavListMenu
            navList={NAV_GIANT_ELPROM.list}
            listTitle={NAV_GIANT_ELPROM.title}
            isEditFavouritesMode={isEditFavouritesMode}
            toggleFavourite={toggleFavourite}
            favourites={favourites}
            formStatus={formStatus}
            alertMessage={alertMessage}
          />
        ) : (
          <>
            <LeftNavListMenu
              navList={NAV_GIANT_HOME.list}
              listTitle=""
              isEditFavouritesMode={isEditFavouritesMode}
              toggleFavourite={toggleFavourite}
              favourites={favourites}
              formStatus={formStatus}
              alertMessage={alertMessage}
            />
            <Divider />
            <LeftNavListMenu
              navList={NAV_GIANT_ANALYTICS.list}
              listTitle={NAV_GIANT_ANALYTICS.title}
              isEditFavouritesMode={isEditFavouritesMode}
              toggleFavourite={toggleFavourite}
              favourites={favourites}
              formStatus={formStatus}
              alertMessage={alertMessage}
            />
            <Divider />
            <LeftNavListMenu
              navList={NAV_GIANT_PLANNING.list}
              listTitle={NAV_GIANT_PLANNING.title}
              isEditFavouritesMode={isEditFavouritesMode}
              toggleFavourite={toggleFavourite}
              favourites={favourites}
              formStatus={formStatus}
              alertMessage={alertMessage}
            />
            <Divider />
            <LeftNavListMenu
              navList={NAV_GIANT_OPERATIONS.list}
              listTitle={NAV_GIANT_OPERATIONS.title}
              isEditFavouritesMode={isEditFavouritesMode}
              toggleFavourite={toggleFavourite}
              favourites={favourites}
              formStatus={formStatus}
              alertMessage={alertMessage}
            />
            <LeftNavListMenu
              navList={NAV_GIANT_TOOLBOX_MANAGER.list}
              listTitle={NAV_GIANT_TOOLBOX_MANAGER.title}
              isEditFavouritesMode={isEditFavouritesMode}
              toggleFavourite={toggleFavourite}
              favourites={favourites}
              formStatus={formStatus}
              alertMessage={alertMessage}
            />
          </>
        )}
      </Box>
    </Drawer>
  );
};

export default LeftNavigation;

import {
  Box,
  IconButton,
  Stack,
  Theme,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import DateAndTimePicker from "../../MaterialUI/DateTimePickers/DateAndTimePicker";
import { BarChartDataSchema, BarParameter } from "./barChartTypes";
import { ExcellenceParameter } from "../../../GraphQL/Excellence/graphQLTypesExcellence";
import cssSpacingStyles from "../../../Global/Styles/spacing";
import cssLayoutStyles from "../../../Global/Styles/layout";
import { css } from "@emotion/react";
import {
  AutocompleteGroupedOption,
  FormStatuses,
  SelectOption,
} from "../../../Global/Types/commonTypes";
import { PieAggregationFunction } from "../PieChart/pieChartTypes";
import { useEffect, useState } from "react";
import Autocomplete from "../../MaterialUI/FormFields/Autocomplete";
import { isValid } from "date-fns";
import Select from "../../MaterialUI/FormFields/Select";
import AddIcon from "@mui/icons-material/Add";
import AutocompleteGrouped from "../../MaterialUI/FormFields/AutocompleteGrouped";
import CloseIcon from "@mui/icons-material/Close";
import { handleGetSelectOption } from "../../../Global/Utils/commonFunctions";
import { ExcellenceParamMapping } from "../EditExcellenceChartForms/excellenceChartFormUtils";
import { useLanguageContext } from "../../../context/LanguageContext";
import {
  GraphqlFilter,
  handleExcellenceParamToGraphqlFilters,
} from "../../SmallComponents/GraphqlFilters/graphqlFiltersUtils";
import GraphqlFilters from "../../SmallComponents/GraphqlFilters/GraphqlFilters";
import Modal from "../../MaterialUI/Modal";
import { FiltersModalLayoutData } from "../excellenceUtils";
import { RemoveFiltersWarning } from "../ExcellenceSmallComponents";

const AGGREGATE_OPTIONS: SelectOption[] = Object.entries(PieAggregationFunction).map(
  ([key, value]) => ({ description: key, value: value })
);

const AGGREGATE_STRING_OPTIONS: SelectOption[] = [
  { description: "Count", value: PieAggregationFunction.Count },
];

const EMPTY_PARAM_ROW = null;
type GroupRow = AutocompleteGroupedOption | null;

const cssStyles = (theme: Theme) => ({
  autocompleteGroup: css({
    position: "sticky",
    top: "-8px",
    padding: "4px 10px",
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.secondary.light,
  }),
  autocompleteList: css({
    padding: 0,
  }),
});

interface EditBarChartDataSchemaProps {
  parameters: ExcellenceParameter[];
  dataSchema: BarChartDataSchema | null;
  handleUpdateDataSchema: (schema: BarChartDataSchema | null) => void;
  dateFrom: Date | null;
  setDateFrom: React.Dispatch<React.SetStateAction<Date | null>>;
  dateTo: Date | null;
  setDateTo: React.Dispatch<React.SetStateAction<Date | null>>;
  selectedConnection: SelectOption;
  setSelectedConnection: React.Dispatch<React.SetStateAction<SelectOption>>;
  graphqlFilters: GraphqlFilter[];
  setGraphqlFilters: React.Dispatch<React.SetStateAction<GraphqlFilter[]>>;
  initialSetupComplete: boolean;
  setInitialSetupComplete: React.Dispatch<React.SetStateAction<boolean>>;
  paramMapping: ExcellenceParamMapping | null;
}

const EditBarChartDataSchema: React.FC<EditBarChartDataSchemaProps> = ({
  parameters,
  dataSchema,
  handleUpdateDataSchema,
  dateFrom,
  setDateFrom,
  dateTo,
  setDateTo,
  selectedConnection,
  setSelectedConnection,
  graphqlFilters,
  setGraphqlFilters,
  initialSetupComplete,
  setInitialSetupComplete,
  paramMapping,
}) => {
  const theme = useTheme();
  const styles = {
    ...cssSpacingStyles(theme),
    ...cssStyles(theme),
    ...cssLayoutStyles,
  };
  const { t } = useLanguageContext();
  // options
  const [connectionOptions, setConnectionOptions] = useState<SelectOption[]>([]);
  const [nodeOptions, setNodeOptions] = useState<AutocompleteGroupedOption[]>([]);
  const [appliedFiltersModal, setAppliedFiltersModal] = useState<FiltersModalLayoutData>({
    isOpen: false,
    data: {value: '', description: ''}
  });

  // selected values
  const [selectedLegend, setSelectedLegend] = useState<AutocompleteGroupedOption | null>(
    null
  );
  const [groupRows, setGroupRows] = useState<GroupRow[]>([]);
  const [selectedOperation, setSelectedOperation] =
    useState<AutocompleteGroupedOption | null>(null);
  const [selectedAggregation, setSelectedAggregation] = useState<string>("");

  // system-used
  const [formStatus, setFormStatus] = useState<FormStatuses>(null);
  const [reUpdateSchema, setReUpdateSchema] = useState<boolean>(false);

  const operationType = paramMapping?.[selectedOperation?.value || ""]?.type;

  useEffect(() => {
    if (graphqlFilters.length && !groupRows.length) {
      setSelectedConnection({
        value: graphqlFilters[0].containerId,
        description: graphqlFilters[0].containerName
      });
      setGroupRows([EMPTY_PARAM_ROW]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (dataSchema && paramMapping) {
      const selectedConnectionState: string =
        (dataSchema.group.length && dataSchema.group[0].container) ||
        (dataSchema.legend && dataSchema?.legend.container) ||
        (dataSchema.operationParameter && dataSchema.operationParameter.container) || '';
        
      // const selectedConnectionsSet: Set<string> = new Set();
      
      // 1. get all selected connections
      // for (const item of dataSchema.group) {
      //   selectedConnectionsSet.add(item.container);
      // }
      // if (dataSchema.legend) {
      //   selectedConnectionsSet.add(dataSchema.legend.container);
      // }
      // if (dataSchema.operationParameter) {
      //   selectedConnectionsSet.add(dataSchema.operationParameter.container);
      // }
      // const selectedConnectionsArr: SelectOption[] = Array.from(
      //   selectedConnectionsSet
      // ).map((item) => {
      //   const conn = Object.values(paramMapping).find(
      //     (param) => param.connection === item
      //   );
      //   const connName = conn?.connectionName || item;

      //   return {
      //     value: item,
      //     description: connName,
      //   };

      const conn = Object.values(paramMapping).find(
        (param) => param.connection === selectedConnectionState
      );
      const connName = conn?.connectionName || selectedConnectionState;

      setSelectedConnection({
        value: selectedConnectionState,
        description: connName,
      });

      const groupParams: GroupRow[] = dataSchema?.group?.map((item) => ({
        value: item.parameterId,
        groupName: item.container,
        description: paramMapping?.[item.parameterId].paramName || item.parameterId,
      }));
      const operation: AutocompleteGroupedOption | null = dataSchema?.operationParameter
        ?.parameterId
        ? {
          value: dataSchema.operationParameter.parameterId,
          groupName:
            paramMapping[dataSchema.operationParameter.parameterId].connectionName,
          description:
            paramMapping[dataSchema.operationParameter.parameterId].paramName,
        }
        : null;
      const legend: AutocompleteGroupedOption | null = dataSchema?.legend?.parameterId
        ? {
          value: dataSchema.legend.parameterId,
          groupName: paramMapping[dataSchema.legend.parameterId].connectionName,
          description: paramMapping[dataSchema.legend.parameterId].paramName,
        }
        : null;

      // 2. set all other selected values
      // setSelectedConnections(selectedConnectionsArr);
      setSelectedLegend(legend);
      setSelectedOperation(operation);
      setSelectedAggregation(dataSchema?.operationParameter?.aggregation || "");
      setGroupRows(groupParams || []);
      setInitialSetupComplete(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paramMapping]);

  useEffect(() => {
    const connectionsRecord: Record<string, string> = {};
    parameters.forEach((item) => {
      connectionsRecord[item.container] = item.containerName;
    });

    const allConnectionOptions: SelectOption[] = Object.entries(connectionsRecord).map(
      ([key, value]) => ({
        value: key,
        description: value,
      })
    );

    handleGetSelectOption([...new Set(parameters.map((item) => item.container))]);
    setConnectionOptions(allConnectionOptions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parameters]);

  useEffect(() => {
    const paramOptions = handleGetParamOptions(parameters, selectedConnection);
    setNodeOptions(paramOptions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedConnection]);

  useEffect(() => {
    const startTime = dateFrom && isValid(dateFrom) ? dateFrom.toISOString() : null;
    const endTime = dateTo && isValid(dateTo) ? dateTo.toISOString() : null;
    const groupParams: BarParameter[] = [];

    if (initialSetupComplete && !selectedConnection.value) {
      handleUpdateDataSchema(null);
    } else {
      for (const row of groupRows) {
        if (paramMapping && row?.value) {
          groupParams.push({
            parameterId: row.value,
            container: paramMapping[row.value].connection,
          });
        }
      }

      if (groupParams.length && groupParams?.[0]?.parameterId) {
        const legendParam = paramMapping?.[selectedLegend?.value || ""];
        const operationParam = paramMapping?.[selectedOperation?.value || ""];

        const updatedSchema: BarChartDataSchema = {
          startTime,
          endTime,
          ...(legendParam && {
            legend: {
              parameterId: legendParam.paramID,
              container: legendParam.connection,
            },
          }),
          ...(operationParam &&
            selectedAggregation && {
            operationParameter: {
              parameterId: operationParam.paramID,
              container: operationParam.connection,
              aggregation: selectedAggregation as PieAggregationFunction,
            },
          }),
          group: groupParams,
        };
        handleUpdateDataSchema(updatedSchema);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reUpdateSchema]);

  const onDateRangeChange = async (val: Date | null, type: "dateTo" | "dateFrom") => {
    try {
      let fullDataRow: boolean = false;
      if (type === "dateTo") {
        setDateTo(val);
        fullDataRow = !!dateFrom && isValid(dateFrom);
      } else {
        setDateFrom(val);
        fullDataRow = !!dateTo && isValid(dateTo);
      }

      if (val && isValid(val) && fullDataRow) {
        setReUpdateSchema((prev) => !prev);
      }
    } catch (err) {
      console.log("err onDateRangeChange() ", err);
      setFormStatus("error");
    }
  };

  const handleOnConnectionChange = (val: SelectOption) => {
    if ( !val && graphqlFilters.length ) {
      setAppliedFiltersModal({isOpen: true, data: {value: "", description: ""}});
    } else if (val && selectedConnection.value !== val.value && graphqlFilters.length) {
      setAppliedFiltersModal({isOpen: true, data: {value: val.value, description: val.description}});
    } else {
      confirmChangeConnection(val);
    }
  };
  const handleOnLegendChange = (val: AutocompleteGroupedOption) => {
    setSelectedLegend(val);
    setReUpdateSchema((prev) => !prev);
  };
  const handleOnOperationChange = (val: AutocompleteGroupedOption) => {
    setSelectedOperation(val);
    setSelectedAggregation("");
    if (!groupRows.length) {
      setGroupRows([EMPTY_PARAM_ROW]);
    }
  };
  const handleOnAggregateChange = (val: string) => {
    setSelectedAggregation(val);
    setReUpdateSchema((prev) => !prev);
    if (!groupRows.length) {
      setGroupRows([EMPTY_PARAM_ROW]);
    }
  };

  const handleAddNewParam = () => {
    setGroupRows((prev) => [...prev, EMPTY_PARAM_ROW]);
  };
  const handleOnNodeChange = (val: AutocompleteGroupedOption, index: number) => {
    const newGroupRows = groupRows.map((item, itemIndex) => {
      if (itemIndex === index) {
        if (val === null) {
          return null;
        } else {
          return val;
        }
      }

      return item;
    });

    setGroupRows(() => newGroupRows);
    setReUpdateSchema((prev) => !prev);
  };

  const handleRemoveParamRow = (index: number) => {
    const newGroupRows = groupRows.filter((_, rowIndex) => index !== rowIndex);
    setGroupRows(newGroupRows);
    setReUpdateSchema((prev) => !prev);
  };

  const confirmChangeConnection = (val: SelectOption ) => {
    setSelectedConnection(val || {value: "", description: ""});
    setSelectedAggregation("");
    setSelectedOperation(null);
    setSelectedLegend(null);
    setGroupRows([EMPTY_PARAM_ROW]);
    if (!val) {
      setReUpdateSchema((prev) => !prev);
    }
    setGraphqlFilters([]);
    setAppliedFiltersModal({isOpen: false, data: {value: '', description: ''}});
  }

  if (!parameters?.length) {
    return (
      <Box component="div">
        <Typography variant="h3" color="error">
          {t("You don't have access to any active connections")}
        </Typography>
      </Box>
    );
  }

  if (formStatus === "error") {
    return (
      <Box component="div">
        <Typography variant="h3" color="error">
          {t("Something went wrong")}
        </Typography>
      </Box>
    );
  }

  return (
    <Stack
      css={styles.contentBreak}
      spacing={3}
      alignItems="center"
      justifyContent="center"
    >
      <Stack css={styles.width100} spacing={3} direction={{ xs: "column", sm: "row" }}>
        <DateAndTimePicker
          css={styles.width100}
          label={t("Date from")}
          value={dateFrom}
          onChange={(val) => onDateRangeChange(val, "dateFrom")}
        />
        <DateAndTimePicker
          css={styles.width100}
          label={t("Date to")}
          value={dateTo}
          onChange={(val) => onDateRangeChange(val, "dateTo")}
        />
      </Stack>

      <Stack css={styles.width100} spacing={3} direction={{ xs: "column", sm: "row" }}>
        <Stack css={[styles.width100, styles.flex, styles.flexAlignItemsEnd]} direction="row" spacing={3} alignItems="center">
          <Box component="div" style={{ flex: 1 }}>
            <Autocomplete
              css={styles.width100}
              label={t("Selected Connection")}
              options={connectionOptions}
              value={selectedConnection}
              handleOnChange={handleOnConnectionChange}
              disabled={formStatus === "loading"}
            />
          </Box>
          {selectedConnection ? (
            <Tooltip title={t("Add New Parameter")}>
              <IconButton css={styles.marginLeftAuto} onClick={handleAddNewParam}>
                <AddIcon />
              </IconButton>
            </Tooltip>
          ) : null}
        </Stack>

        <Box css={[styles.flex, styles.flexAlignItemsEnd]} component="div">
          <GraphqlFilters
            key={graphqlFilters.length}
            data={handleExcellenceParamToGraphqlFilters(parameters)}
            graphqlFilters={graphqlFilters}
            setGraphqlFilters={setGraphqlFilters}
            selectedContainers={[selectedConnection.value]}
          />
        </Box>
      </Stack>

      {selectedConnection && groupRows.length ? (
        <Stack
          css={[styles.width100, styles.overflowYAuto]}
          sx={{ maxHeight: "250px" }}
          gap={3}
        >
          {groupRows.map((item, index) => (
            <Stack
              style={{ width: "99%" }}
              key={`item-index-${index}`}
              direction="row"
              gap={3}
              alignItems="center"
            >
              <AutocompleteGrouped
                css={styles.width100}
                label={`${t("Group Parameter")} ${index + 1}`}
                options={nodeOptions}
                value={item}
                handleOnChange={(e: AutocompleteGroupedOption) =>
                  handleOnNodeChange(e, index)
                }
                disabled={formStatus === "loading"}
              />
              {index === 0 ? null : (
                <Tooltip title={t("Remove Parameter")}>
                  <IconButton
                    css={styles.marginLeftAuto}
                    onClick={() => handleRemoveParamRow(index)}
                  >
                    <CloseIcon />
                  </IconButton>
                </Tooltip>
              )}
            </Stack>
          ))}
        </Stack>
      ) : null}

      {groupRows?.[0]?.value ? (
        <>
          <Stack css={styles.width100} direction="row" spacing={3} alignItems="center">
            <AutocompleteGrouped
              css={styles.width100}
              options={nodeOptions}
              label={t("Aggregation Name")}
              value={selectedOperation}
              handleOnChange={handleOnOperationChange}
            />
            <Select
              selectOptions={
                operationType === "string" ? AGGREGATE_STRING_OPTIONS : AGGREGATE_OPTIONS
              }
              label={t(`Aggregate Function`)}
              value={selectedAggregation}
              onChange={(e) => handleOnAggregateChange(e.target.value)}
              disabled={formStatus === "loading" || !selectedOperation}
            />
          </Stack>

          {selectedOperation && selectedAggregation ? (
            <AutocompleteGrouped
              css={styles.width100}
              options={nodeOptions}
              label={t("Legend")}
              value={selectedLegend}
              handleOnChange={handleOnLegendChange}
            />
          ) : null}
        </>
      ) : null}

      <Modal
        open={appliedFiltersModal.isOpen}
        onClose={() => setAppliedFiltersModal(prev => ({...prev, isOpen: false}))}
        fullWidth
        label={t("Warning")}
      >
        <RemoveFiltersWarning
          confirmChangeConnection={() => confirmChangeConnection(appliedFiltersModal.data)}
          onClose={() => setAppliedFiltersModal(prev => ({ ...prev, isOpen: false }))}
        />
      </Modal>
    </Stack>
  );
};

export default EditBarChartDataSchema;

const handleGetParamOptions = (
  parameters: ExcellenceParameter[],
  selectedConnection: SelectOption
): AutocompleteGroupedOption[] => {
  const result =
    parameters
      .map((item) => ({
        groupName: item.container,
        value: item.id,
        description: item.name,
      }))
      .filter((node) =>
        node.groupName === selectedConnection.value
      ) || [];

  const resultWithGroupNames = result.map((item) => {
    const found = selectedConnection.value === item.groupName ? selectedConnection : null;
    const name = found?.description || item.groupName;
    return {
      ...item,
      groupName: name,
    };
  });

  return resultWithGroupNames;
};

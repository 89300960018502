import { Badge, Box, IconButton, Tooltip } from "@mui/material";
import { GraphqlFilter, GraphqlFiltersData } from "./graphqlFiltersUtils";
import { useState } from "react";
import GraphqlFiltersModal from "./GraphqlFiltersModal";
import FilterAltIcon from "@mui/icons-material/FilterAlt";

interface GraphqlFiltersProps {
  data: GraphqlFiltersData[];
  selectedContainers: string[];
  graphqlFilters: GraphqlFilter[];
  setGraphqlFilters: React.Dispatch<React.SetStateAction<GraphqlFilter[]>>;
}

const GraphqlFilters: React.FC<GraphqlFiltersProps> = ({
  data,
  selectedContainers,
  graphqlFilters,
  setGraphqlFilters,
}) => {
  const [openFilters, setOpenFilters] = useState<boolean>(false);

  return (
    <Box component="div">
      <Tooltip 
        title={`${graphqlFilters.length} Filters Applied`} 
        placement="top"
      >
        <IconButton onClick={() => setOpenFilters(true)}>
          <Badge badgeContent={graphqlFilters.length} color="secondary">
            <FilterAltIcon />
          </Badge>
        </IconButton>
      </Tooltip>

      <GraphqlFiltersModal
        openFilters={openFilters}
        setOpenFilters={setOpenFilters}
        graphqlFilters={graphqlFilters}
        onSave={setGraphqlFilters}
        data={data}
        selectedContainers={selectedContainers}
      />
    </Box>
  );
};

export default GraphqlFilters;
